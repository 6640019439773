import React from 'react';


import { StyleSheet, Text, TextProps } from 'react-native';
import { LinearGradientProps } from 'react-native-linear-gradient';
import navigationService from "../services/navigation-service";

const GradientText: React.FC<TextProps & { gradient: LinearGradientProps }> = (props) => {
    return (
        <navigationService.props.MaskedView maskElement={<Text {...props} />}>
            <navigationService.props.LinearGradient
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                {...props.gradient}
                style={StyleSheet.absoluteFill}
            />
            <Text {...props} style={[props.style, styles.mask]} />
        </navigationService.props.MaskedView>
    );
};

const styles = StyleSheet.create({
    mask: {
        opacity: 0,
    },
});

export default GradientText;