/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUnsubcribedEmails = /* GraphQL */ `
  subscription OnCreateUnsubcribedEmails(
    $filter: ModelSubscriptionUnsubcribedEmailsFilterInput
  ) {
    onCreateUnsubcribedEmails(filter: $filter) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUnsubcribedEmails = /* GraphQL */ `
  subscription OnUpdateUnsubcribedEmails(
    $filter: ModelSubscriptionUnsubcribedEmailsFilterInput
  ) {
    onUpdateUnsubcribedEmails(filter: $filter) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUnsubcribedEmails = /* GraphQL */ `
  subscription OnDeleteUnsubcribedEmails(
    $filter: ModelSubscriptionUnsubcribedEmailsFilterInput
  ) {
    onDeleteUnsubcribedEmails(filter: $filter) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLyriPost = /* GraphQL */ `
  subscription OnCreateLyriPost($filter: ModelSubscriptionLyriPostFilterInput) {
    onCreateLyriPost(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLyriPost = /* GraphQL */ `
  subscription OnUpdateLyriPost($filter: ModelSubscriptionLyriPostFilterInput) {
    onUpdateLyriPost(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLyriPost = /* GraphQL */ `
  subscription OnDeleteLyriPost($filter: ModelSubscriptionLyriPostFilterInput) {
    onDeleteLyriPost(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTopLyriPost = /* GraphQL */ `
  subscription OnCreateTopLyriPost(
    $filter: ModelSubscriptionTopLyriPostFilterInput
  ) {
    onCreateTopLyriPost(filter: $filter) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTopLyriPost = /* GraphQL */ `
  subscription OnUpdateTopLyriPost(
    $filter: ModelSubscriptionTopLyriPostFilterInput
  ) {
    onUpdateTopLyriPost(filter: $filter) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTopLyriPost = /* GraphQL */ `
  subscription OnDeleteTopLyriPost(
    $filter: ModelSubscriptionTopLyriPostFilterInput
  ) {
    onDeleteTopLyriPost(filter: $filter) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRiddlePost = /* GraphQL */ `
  subscription OnCreateRiddlePost(
    $filter: ModelSubscriptionRiddlePostFilterInput
  ) {
    onCreateRiddlePost(filter: $filter) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRiddlePost = /* GraphQL */ `
  subscription OnUpdateRiddlePost(
    $filter: ModelSubscriptionRiddlePostFilterInput
  ) {
    onUpdateRiddlePost(filter: $filter) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRiddlePost = /* GraphQL */ `
  subscription OnDeleteRiddlePost(
    $filter: ModelSubscriptionRiddlePostFilterInput
  ) {
    onDeleteRiddlePost(filter: $filter) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSoundtrack = /* GraphQL */ `
  subscription OnCreateSoundtrack(
    $filter: ModelSubscriptionSoundtrackFilterInput
  ) {
    onCreateSoundtrack(filter: $filter) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSoundtrack = /* GraphQL */ `
  subscription OnUpdateSoundtrack(
    $filter: ModelSubscriptionSoundtrackFilterInput
  ) {
    onUpdateSoundtrack(filter: $filter) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSoundtrack = /* GraphQL */ `
  subscription OnDeleteSoundtrack(
    $filter: ModelSubscriptionSoundtrackFilterInput
  ) {
    onDeleteSoundtrack(filter: $filter) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePromoters = /* GraphQL */ `
  subscription OnCreatePromoters(
    $filter: ModelSubscriptionPromotersFilterInput
  ) {
    onCreatePromoters(filter: $filter) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePromoters = /* GraphQL */ `
  subscription OnUpdatePromoters(
    $filter: ModelSubscriptionPromotersFilterInput
  ) {
    onUpdatePromoters(filter: $filter) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePromoters = /* GraphQL */ `
  subscription OnDeletePromoters(
    $filter: ModelSubscriptionPromotersFilterInput
  ) {
    onDeletePromoters(filter: $filter) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserPostLikes = /* GraphQL */ `
  subscription OnCreateUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onCreateUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserPostLikes = /* GraphQL */ `
  subscription OnUpdateUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onUpdateUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserPostLikes = /* GraphQL */ `
  subscription OnDeleteUserPostLikes(
    $filter: ModelSubscriptionUserPostLikesFilterInput
  ) {
    onDeleteUserPostLikes(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLyriComment = /* GraphQL */ `
  subscription OnCreateLyriComment(
    $filter: ModelSubscriptionLyriCommentFilterInput
  ) {
    onCreateLyriComment(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLyriComment = /* GraphQL */ `
  subscription OnUpdateLyriComment(
    $filter: ModelSubscriptionLyriCommentFilterInput
  ) {
    onUpdateLyriComment(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLyriComment = /* GraphQL */ `
  subscription OnDeleteLyriComment(
    $filter: ModelSubscriptionLyriCommentFilterInput
  ) {
    onDeleteLyriComment(filter: $filter) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLyriNotification = /* GraphQL */ `
  subscription OnCreateLyriNotification(
    $filter: ModelSubscriptionLyriNotificationFilterInput
  ) {
    onCreateLyriNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLyriNotification = /* GraphQL */ `
  subscription OnUpdateLyriNotification(
    $filter: ModelSubscriptionLyriNotificationFilterInput
  ) {
    onUpdateLyriNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLyriNotification = /* GraphQL */ `
  subscription OnDeleteLyriNotification(
    $filter: ModelSubscriptionLyriNotificationFilterInput
  ) {
    onDeleteLyriNotification(filter: $filter) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserMessageThreads = /* GraphQL */ `
  subscription OnCreateUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onCreateUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserMessageThreads = /* GraphQL */ `
  subscription OnUpdateUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onUpdateUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserMessageThreads = /* GraphQL */ `
  subscription OnDeleteUserMessageThreads(
    $filter: ModelSubscriptionUserMessageThreadsFilterInput
  ) {
    onDeleteUserMessageThreads(filter: $filter) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserMessage = /* GraphQL */ `
  subscription OnCreateUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onCreateUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserMessage = /* GraphQL */ `
  subscription OnUpdateUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onUpdateUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserMessage = /* GraphQL */ `
  subscription OnDeleteUserMessage(
    $filter: ModelSubscriptionUserMessageFilterInput
  ) {
    onDeleteUserMessage(filter: $filter) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
