import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform, AsyncStorage } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';

interface State {


}
interface Props {
    style?: ViewStyle
    data: any[]
    onPress: any
}
const fadeInOut = {
    0: {
        opacity: 0.5
    },
    0.5: {
        opacity: 1
    },
    1: {
        opacity: 0.5
    }
};

class IconMenu extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);

        this.state = {

        }

    }

    itemPress = async (item: any) => {
        this.props.onPress(item);
    }

    async componentDidMount() {
    }


    render() {
        return (
            <View style={[this.props.style, style.column, {}]}>
                {this.props.data.map((item: any, index: any) => {
                    return <TouchableOpacity key={index} onPress={() => {
                        this.itemPress(item)
                    }} style={[style.fullWidth]}>
                        <View style={[style.row, style.fullWidth, style.vcenter, { marginTop: 0, padding: 10 }]}>

                            <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, backgroundColor: colors.pinkish }]}>
                                {React.cloneElement(item.icon, { color: colors.greaysh, size: 14 })}
                            </View>
                            <Text style={{
                                marginLeft: 20,
                                flex: 1,
                                fontSize: 18,
                                color: colors.textColor,
                                fontFamily: 'Jost'
                            }}>{item.label}</Text>
                        </View>
                    </TouchableOpacity>
                })}
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default IconMenu;
