import { Entypo, Feather, FontAwesome, FontAwesome5, Ionicons } from '@expo/vector-icons';
import { AsyncStorage, Dimensions, Platform, SafeAreaView, TextInput } from 'react-native';
import {
  NativeStackHeaderProps,
  NativeStackNavigationOptions,
  NativeStackNavigationProp
} from '@react-navigation/native-stack';
import { ParamListBase, Route } from '@react-navigation/routers';
import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from '../services/navigation-service';
import { AppContext, IContext, IAppState } from '../store/app-provider';
import UserButton from "./user-button";
import { SheetManager } from "react-native-actions-sheet";
import GradientText from './gradient-text';
import * as Animatable from 'react-native-animatable';
interface State {
  opacity?: number,
}
const fadeInOut = {
  0: {
    opacity: 0.5
  },
  0.5: {
    opacity: 1
  },
  1: {
    opacity: 0.5
  }
};
class Header extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  title: string = "";
  backScreen: string = "";

  constructor(props: any) {
    super(props);
    this.state = { opacity: Platform.OS === 'web' ? 1 : 1 }
    this.title = props.route.name

    //this.backScreen = (this.props.app.screen !== "Home") ? "true" : ""

    //(props.back?.title || "")
    // && Platform.OS !== "web";
  }

  gotoProfile() {
    return;
    if (!this.context.state.user) {
      this.context.setScreen("Sign In", true);
    } else {
      SheetManager.show(`user_menu`);
    }
  }

  gotoHome(header?: boolean) {
    this.context.setScreen("Home", true);
  }

  goBack() {
    //navigationService.pop();
    this.gotoHome()
  }

  goBackBack() {
    navigationService.pop();

  }

  openMenu() {
    SheetManager.show("menu_sheet");
  }
  async componentDidMount() {
    setTimeout(async () => {
      this.setState({ opacity: 1 })
      if (Platform.OS === 'web') return;



    }, 2000)
  }
  componentWillUnmount() {

  }
  getRemainingDaysAndHours() {
    const remaining = (this.context.state.user?.premiumUntil || 0) - (new Date().getTime())
    const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remaining / (1000 * 60 * 60)) % 24);
    return `${days}d ${hours}h`
  }

  render() {
    //console.log("render", this.props)
    return (
      <SafeAreaView>
        <View style={[{

          height: Platform.OS === 'web' ? 70 : 70,
          width: '100%',
          marginTop: 0,
          display: "flex",
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: colors.backgroundColor,
          paddingBottom: Platform.OS === 'web' ? 0 : 0,
          paddingLeft: 10,
          paddingRight: 10
        }]}>


          <View style={[{
            opacity: this.state.opacity,
            height: '100%',
            width: 100,
          }, style.row, style.vcenter, style.hleft]}>
            {(this.context.state.screen && this.context.state.screen === "Home") || !this.context.state.screen ? <TouchableOpacity onPress={() => {
              this.context.setAppProp({ appMenuOpened: true, appMenuOpenedBack: true })
            }}>
              <Animatable.View duration={1000} iterationCount="infinite" animation={undefined} style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, marginRight: 5, borderRadius: 19, backgroundColor: colors.pinkish }]}>
                <Feather name="menu" size={20} color={colors.greaysh} />
              </Animatable.View>
            </TouchableOpacity> : null}

            {this.context.state.screen && this.context.state.screen !== "Home" ?

              <TouchableOpacity onPress={this.goBack.bind(this)}>
                <View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, marginRight: 5, borderRadius: 19, backgroundColor: colors.pinkish }]}>
                  <Feather name="home" size={20} color={colors.greaysh} />
                </View>
              </TouchableOpacity>
              : null}
            {!this.context.state.user?.isOnCampaign && this.context.state.checkedStore && (new Date().getTime()) < (this.context.state.user?.premiumUntil || 0) ? <Text style={{ fontSize: 12, fontWeight: 'bold', marginRight: 5, color: colors.bluePrimary, fontFamily: 'Jost' }}>{this.getRemainingDaysAndHours()}</Text> : null}
            {this.context.state.user?.isOnCampaign && this.context.state.checkedStore && !this.context.state.isPremium ? <Feather name="eye" style={{ marginRight: 5 }} size={16} c color={colors.bluePrimary} /> : null}
            {(!this.context.state.user?.isOnCampaign && Platform.OS !== "web" && this.context.state.checkedStore && !this.context.state.isPremium) ? <TouchableOpacity onPress={() => {
              if (!this.context.state.user?.isOnCampaign) {
                SheetManager.show("go_campaign");
              }
            }} style={{}}><View style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, marginRight: 5, backgroundColor: colors.pinkish }]}><Animatable.Text direction="alternate" iterationCount="infinite" duration={1000} animation="fadeIn" style={{ fontSize: 16, fontWeight: 'bold', color: colors.bluePrimary, fontFamily: 'Jost' }}>{this.context.state.user?.campaignLikes || 0}</Animatable.Text></View></TouchableOpacity> : null}
            {(this.context.state.user?.isOnCampaign && this.context.state.checkedStore && !this.context.state.isPremium) ? <Text style={{ fontSize: 16, fontWeight: 'bold', marginRight: 5, color: colors.bluePrimary, fontFamily: 'Jost' }}>{this.context.state.user?.campaignLikes || 0}</Text> : null}
          </View>
          <TouchableOpacity style={{ flex: 1, opacity: this.state.opacity }} onPress={this.goBack.bind(this)}>
            {Platform.OS === 'web' || 1 === 1 ? <View style={[style.row, style.hcenter, { flex: 1 }]}><Image style={[{
              height: 125 * 0.4,
              width: 421 * 0.4,
              marginLeft: Platform.OS !== 'web' ? 10 : 0,
              marginTop: Platform.OS !== 'web' ? 12 : 0,
            }]} source={{ uri: navigationService.props.logo }}></Image></View> : <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
              {Platform.OS === 'android' ? <navigationService.props.LinearTextGradient style={{}} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} colors={['#b1d9eb', '#d5b5c6', '#c4cde9']} locations={[0, 0.43, 1]}><Text style={{ fontSize: 34, fontFamily: 'Oleo' }}>Lyri</Text></navigationService.props.LinearTextGradient> : <GradientText gradient={{ colors: ['#b1d9eb', '#d5b5c6', '#c4cde9'], locations: [0, 0.43, 1] }} style={{ fontSize: 34, fontFamily: 'Oleo' }}> Lyri</GradientText>}
              <Text style={{ fontSize: 34, color: colors.neutralBlue, fontFamily: 'Oleo' }}>tunes</Text>
            </View>}
          </TouchableOpacity>
          <View style={[{ width: 100, height: '100%', opacity: this.state.opacity }, style.row, style.vcenter, style.hright]}>

            {this.context.state.user ? <TouchableOpacity onPress={() => {
              SheetManager.show(`notifications_sheet`);
            }}>
              <Animatable.View duration={1000} iterationCount="infinite" animation={this.context.state.hasUnseenNotifications ? fadeInOut : undefined} style={[style.row, style.vcenter, style.hcenter, { width: 38, height: 38, borderRadius: 19, marginRight: 5, backgroundColor: colors.pinkish }]}>
                <Ionicons name="notifications" size={20} color={colors.greaysh} />
              </Animatable.View>
            </TouchableOpacity> : null}
            {this.context.state.user ? <UserButton user={this.context.state.user} size={36} onPress={this.gotoProfile.bind(this)}></UserButton> : null}
          </View>

        </View>
      </SafeAreaView>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});
//export default Header;
type Props = {
  back?: {
    title: string;
  },
  title: string,
  t: any,
  options: NativeStackNavigationOptions,
  route: Route<string>;
  navigation: NativeStackNavigationProp<ParamListBase>,
  user: any,
  userPicture: any,
  app: any
}

export default Header
