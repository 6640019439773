import * as React from 'react';
import {
    Text,
    TouchableOpacity,
    StyleSheet,
    View,
    Image,
    ViewStyle,
    TextInput,
    ScrollView,
    Platform, ImageBackground, Linking, AsyncStorage
} from 'react-native';
import colors from '../constants/colors';
const CryptoJS = require('crypto-js');
import * as Animatable from 'react-native-animatable';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { API, graphqlOperation, Storage } from "aws-amplify";
import navigationService from "../services/navigation-service";
//@ts-ignore
import UserButton from "./user-button";
import PrimaryButton from "./primary-button";
import { LyriPost, PostType } from "../models";
import LyriItemBar from "./lyri-iitem-bar";
import { Feather, FontAwesome, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import LyricsPlayer from "./lyrics-player";
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import {
    updateRiddlePost
} from "./../graphql/mutations";
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import Alert from './alert';

interface State {
    backgroundImage?: string
    expanded?: boolean
    finished?: boolean
}

interface Props {
    item: any,
    index?: number
    hasRewarded?: boolean
    onPress: any
    style?: ViewStyle
}
const fadeInOut = {
    0: {
        opacity: 0.8
    },
    0.5: {
        opacity: 1
    },
    1: {
        opacity: 0.8
    }
};
class RiddleItem extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        this.state = {}

    }

    openUserMenu(user: any) {

        this.context.openReportUser(user);
    }

    async componentDidMount() {

        const backgroundImage = this.props.item.background ? await Storage.get(this.props.item.background, { level: 'public' }) : "";
        this.setState({ backgroundImage });
    }
    playRewardedAdAndView = () => {
        this.context.playRewardedAdAndGenerate(this.itemPress.bind(this, true));
    }
    itemPress = async (force?: boolean) => {
        if (this.props.item.ipAnswers?.indexOf(this.context.state.publicIp) !== -1) return;
        console.log("itemPress", this.props.item, force)
        const bytes = CryptoJS.AES.decrypt(this.props.item.correctAnswer, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369kakaiaiaia");
        const correctAnswer = bytes.toString(CryptoJS.enc.Utf8);
        console.log("onCorrectAnswer", correctAnswer);
        let numberOfAnswers = parseInt(await AsyncStorage.getItem("numberOfAnswers") || "0") || 0;
        numberOfAnswers++;
        AsyncStorage.setItem("numberOfAnswers", numberOfAnswers.toString());
        if (this.props.hasRewarded && !this.context.state.isPremium && !force && numberOfAnswers % 2 === 0) {
            this.context.showRewarded(this.itemPress.bind(this, true))
            return;
        }
        if (this.props.onPress && this.props.item.ipAnswers?.indexOf(this.context.state.publicIp) === -1) this.props.onPress(this.props.item);

    }
    openLinkInBrowser() {
        if (Platform.OS === 'web') {
            (window as any).open(this.props.item.originalNewsLink, '_blank');

        } else {
            InAppBrowser.open(this.props.item.originalNewsLink)
        }
    }

    reportUser() {

    }

    render() {
        const textu = () => {
            return <Text onLayout={(event) => {

            }} style={[style.fullSize, {
                color: colors.textColor,
                fontSize: 15,
                lineHeight: colors.lineHeight,
                paddingLeft: 5,
                paddingRight: 5
            }]}>
                {this.props.item.lyrics}
            </Text>

        }
        return (

            this.props.item ?
                <View style={{ width: '100%', padding: 10, paddingTop: 20 }}>

                    {!this.context.state.isPremium && (this.props.index || 0) !== 0 && ((this.props.index || 0)) % this.context.state.adData?.banSkip === 0 && this.context.state.adData?.enabled && this.context.state.checkedStore && Platform.OS !== 'web' && this.context.state.appConfig ?
                        <View style={[{
                            marginTop: 0,
                            backgroundColor: '#fff',
                            height: 260,
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 5,
                            width: '100%',
                        }]}>

                            <navigationService.props.BannerAd onAdFailedToLoad={() => {

                            }}
                                unitId={this.context.state.adData?.ban}
                                size={navigationService.props.BannerAdSize.MEDIUM_RECTANGLE}
                                requestOptions={{
                                    requestNonPersonalizedAdsOnly: this.context.state.requestNonPersonalizedAdsOnly
                                }}
                            />
                        </View> : null}


                    <View style={[{
                        backgroundColor: '#fff',
                        marginBottom: 5,
                        width: '100%',
                    }]}>

                        <TouchableOpacity style={[style.fullWidth]} onPress={() => {
                            this.itemPress();

                        }}>



                            <View style={{
                                width: '100%',
                                overflow: 'hidden',
                                borderWidth: 0,
                                borderRadius: colors.borderRadius,
                                padding: 20,
                                paddingLeft: 30,
                                opacity: 1,
                                backgroundColor: "#f0f3fe",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start'
                            }}>

                                <Text style={[{
                                    color: colors.textColor,
                                    fontSize: 18,
                                    opacity: this.props.item.ipAnswers?.indexOf(this.context.state.publicIp) !== -1 ? 0.3 : 1,
                                    fontFamily: 'Jost'

                                }]}>
                                    {this.props.item.lyrics}
                                </Text>
                                <View style={[style.row, style.fullWidth, style.vcenter, { marginTop: 15 }]}>
                                    <FontAwesome name="check" size={16} color={colors.bluePrimary} />
                                    <Text style={{
                                        marginLeft: 10,
                                        fontSize: 16,
                                        fontFamily: 'Jost',
                                        color: colors.textColor
                                    }}>{this.props.item.correctAnswers}</Text>
                                    <Feather name="x" style={{ marginLeft: 10 }} size={20} color={colors.bluePrimary} />
                                    <Text style={{
                                        marginLeft: 10,
                                        fontSize: 16,
                                        fontFamily: 'Jost',
                                        color: colors.textColor
                                    }}>{this.props.item.wrongAnswers}</Text>
                                </View>



                            </View>



                        </TouchableOpacity>




                    </View>

                </View > : <View></View>

        )
    };

}

const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});

export default RiddleItem;
