import * as React from 'react';
import { ActivityIndicator, AsyncStorage, Linking, Platform, StyleSheet, Switch, Text, TextInput, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { Ionicons } from "@expo/vector-icons";
import LyriItem from "../components/lyri-item";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import navigationService from '../services/navigation-service';
import RiddleItem from '../components/riddle-item';
import ActionSheet, { SheetManager } from 'react-native-actions-sheet';
import PrimaryButton from '../components/primary-button';
const CryptoJS = require('crypto-js');
const getNext9AM = () => {
    const now = new Date();
    let next9AM = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);

    // If it's already past 9 AM, set next9AM to 9 AM tomorrow
    if (now.getTime() > next9AM.getTime()) {
        next9AM.setDate(next9AM.getDate() + 1);
    }

    return next9AM;
};
interface Props {

}

interface State {
    answer: string
    isBusy?: boolean
    selectedRiddle?: any
}

class RiddlesScreen extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        //day is just the name of today's day like Monday, Tuesday, etc.
        const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][new Date().getDay()];

        this.state = {
            isBusy: false,
            answer: ""
        }
    }

    async componentDidMount() {

    }

    async getRiddles(token?: string) {
        let riddlesList = (await API.graphql({
            query: queries.getRiddlesByTime,
            variables: { type: "riddle", limit: 10, filter: {}, sortDirection: 'DESC', nextToken: token },
        }) as any).data.getRiddlesByTime;

        riddlesList.items = riddlesList.items.map((r: any) => {
            r.ipAnswers = r.ipAnswers || [];
            return r;
        })
        return { items: riddlesList.items, nextToken: riddlesList.nextToken };
    }

    componentDidUpdate(prevPops: any) {

    }
    async onCorrectAnswer() {
        if (!this.state.answer.length) {
            return;
        }
        if (this.state.answer.split(' ').length > 1) {
            this.context.showToast('Please enter only one word as an answer', 'error');
            return;
        }
        this.setState({ isBusy: true });
        await this.context.answerRiddle(this.state.selectedRiddle, this.state.answer);
        this.setState({ isBusy: false });
        SheetManager.hide('riddle-answer');
    }
    render() {
        const renderItem = ({ item, index }: any) => {
            return <RiddleItem hasRewarded={true} index={index} onPress={(item: any) => {
                this.context.logEvent('riddle_view');
                this.setState({ selectedRiddle: item });
                SheetManager.show('riddle-answer');
            }} item={item} />

        }
        const busyIndicator = () => {
            if (this.context.state.isBusy || this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }

        return (

            <View style={style.page}>
                <View style={[style.container]}>
                    <ItemsList disableBottomScroll={true} disableRefresh={false} cache={false} renderItem={renderItem} stateKey={'riddlesList'} fn={this.getRiddles.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.riddlesList}></ItemsList>

                </View>
                <ActionSheet containerStyle={{
                    maxWidth: 500, borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRadius: colors.borderRadius
                }} defaultOverlayOpacity={0.5} id="riddle-answer">
                    <View style={[style.fullWidth, style.hcenter, style.vcenter, { padding: 20 }]}>
                        <Text style={{
                            marginLeft: 10,
                            width: '100%',
                            fontSize: 18,
                            marginBottom: 20,
                            fontFamily: 'Jost'
                        }}>The correct answer is:</Text>
                        <TextInput autoCapitalize={'sentences'} autoCorrect={true} blurOnSubmit={true} maxLength={250}
                            enablesReturnKeyAutomatically={true} onSubmitEditing={() => {
                                if (!this.state.isBusy) this.onCorrectAnswer();
                            }} placeholder='' multiline={false} numberOfLines={1} onChangeText={(text: string) => {
                                this.setState({ answer: text });
                            }} style={[style.fullWidth, style.textInput, {
                                textAlignVertical: 'center',
                                fontFamily: 'Jost',
                                marginBottom: 20,
                                color: colors.neutralBlue
                            }]}></TextInput>
                        <PrimaryButton progress={false} align={'left'} isBusy={this.state.isBusy} onPress={this.onCorrectAnswer.bind(this)} label={'Answer'} style={{
                            width: '100%',
                            marginBottom: 0,
                            marginTop: 10
                        }} />
                    </View>

                </ActionSheet>
            </View>
        )
    };

}

export default RiddlesScreen;

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});
