/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      isOnCampaign
      premiumUntil
      campaignLikes
      noOfCampaigns
      isPremium
      isPremiumSubscription
      blockedUserIds
      reports
      devices {
        token
        platform
        __typename
      }
      deviceToken
      email
      picture
      firstName
      lastName
      riddleGuessType
      userRiddleGuesses
      userLikeType
      userLikes
      userPostsType
      userPosts
      userSharesType
      userShares
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUnsubcribedEmails = /* GraphQL */ `
  mutation CreateUnsubcribedEmails(
    $input: CreateUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    createUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUnsubcribedEmails = /* GraphQL */ `
  mutation UpdateUnsubcribedEmails(
    $input: UpdateUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    updateUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUnsubcribedEmails = /* GraphQL */ `
  mutation DeleteUnsubcribedEmails(
    $input: DeleteUnsubcribedEmailsInput!
    $condition: ModelUnsubcribedEmailsConditionInput
  ) {
    deleteUnsubcribedEmails(input: $input, condition: $condition) {
      id
      emails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLyriPost = /* GraphQL */ `
  mutation CreateLyriPost(
    $input: CreateLyriPostInput!
    $condition: ModelLyriPostConditionInput
  ) {
    createLyriPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLyriPost = /* GraphQL */ `
  mutation UpdateLyriPost(
    $input: UpdateLyriPostInput!
    $condition: ModelLyriPostConditionInput
  ) {
    updateLyriPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLyriPost = /* GraphQL */ `
  mutation DeleteLyriPost(
    $input: DeleteLyriPostInput!
    $condition: ModelLyriPostConditionInput
  ) {
    deleteLyriPost(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      screenshot
      style
      background
      ipLikes
      tone
      originalNewsLink
      newsDate
      backgroundPremium
      isNeural
      isGIF
      lyrics
      soundtrack
      soundtrackPremium
      isExplicit
      socialThemeKeyword
      horoscopeSavedAt
      horoscopeDate
      socialType
      socialSavedAt
      postSong
      postVideo
      type
      title
      reports
      numLikes
      numViews
      numComments
      isPTwetter
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopLyriPost = /* GraphQL */ `
  mutation CreateTopLyriPost(
    $input: CreateTopLyriPostInput!
    $condition: ModelTopLyriPostConditionInput
  ) {
    createTopLyriPost(input: $input, condition: $condition) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopLyriPost = /* GraphQL */ `
  mutation UpdateTopLyriPost(
    $input: UpdateTopLyriPostInput!
    $condition: ModelTopLyriPostConditionInput
  ) {
    updateTopLyriPost(input: $input, condition: $condition) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopLyriPost = /* GraphQL */ `
  mutation DeleteTopLyriPost(
    $input: DeleteTopLyriPostInput!
    $condition: ModelTopLyriPostConditionInput
  ) {
    deleteTopLyriPost(input: $input, condition: $condition) {
      id
      lyriPostId
      lyriPost {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRiddlePost = /* GraphQL */ `
  mutation CreateRiddlePost(
    $input: CreateRiddlePostInput!
    $condition: ModelRiddlePostConditionInput
  ) {
    createRiddlePost(input: $input, condition: $condition) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRiddlePost = /* GraphQL */ `
  mutation UpdateRiddlePost(
    $input: UpdateRiddlePostInput!
    $condition: ModelRiddlePostConditionInput
  ) {
    updateRiddlePost(input: $input, condition: $condition) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRiddlePost = /* GraphQL */ `
  mutation DeleteRiddlePost(
    $input: DeleteRiddlePostInput!
    $condition: ModelRiddlePostConditionInput
  ) {
    deleteRiddlePost(input: $input, condition: $condition) {
      id
      ipAnswers
      correctAnswers
      wrongAnswers
      lyrics
      correctAnswer
      type
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSoundtrack = /* GraphQL */ `
  mutation CreateSoundtrack(
    $input: CreateSoundtrackInput!
    $condition: ModelSoundtrackConditionInput
  ) {
    createSoundtrack(input: $input, condition: $condition) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSoundtrack = /* GraphQL */ `
  mutation UpdateSoundtrack(
    $input: UpdateSoundtrackInput!
    $condition: ModelSoundtrackConditionInput
  ) {
    updateSoundtrack(input: $input, condition: $condition) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSoundtrack = /* GraphQL */ `
  mutation DeleteSoundtrack(
    $input: DeleteSoundtrackInput!
    $condition: ModelSoundtrackConditionInput
  ) {
    deleteSoundtrack(input: $input, condition: $condition) {
      id
      title
      category
      s3Key
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPromoters = /* GraphQL */ `
  mutation CreatePromoters(
    $input: CreatePromotersInput!
    $condition: ModelPromotersConditionInput
  ) {
    createPromoters(input: $input, condition: $condition) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePromoters = /* GraphQL */ `
  mutation UpdatePromoters(
    $input: UpdatePromotersInput!
    $condition: ModelPromotersConditionInput
  ) {
    updatePromoters(input: $input, condition: $condition) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePromoters = /* GraphQL */ `
  mutation DeletePromoters(
    $input: DeletePromotersInput!
    $condition: ModelPromotersConditionInput
  ) {
    deletePromoters(input: $input, condition: $condition) {
      id
      tiktokId
      avatarUrl
      previewUrl
      videoUrl
      tiktokProfile
      type
      description
      addedTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserPostLikes = /* GraphQL */ `
  mutation CreateUserPostLikes(
    $input: CreateUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    createUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserPostLikes = /* GraphQL */ `
  mutation UpdateUserPostLikes(
    $input: UpdateUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    updateUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserPostLikes = /* GraphQL */ `
  mutation DeleteUserPostLikes(
    $input: DeleteUserPostLikesInput!
    $condition: ModelUserPostLikesConditionInput
  ) {
    deleteUserPostLikes(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      post {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        screenshot
        style
        background
        ipLikes
        tone
        originalNewsLink
        newsDate
        backgroundPremium
        isNeural
        isGIF
        lyrics
        soundtrack
        soundtrackPremium
        isExplicit
        socialThemeKeyword
        horoscopeSavedAt
        horoscopeDate
        socialType
        socialSavedAt
        postSong
        postVideo
        type
        title
        reports
        numLikes
        numViews
        numComments
        isPTwetter
        savedAt
        createdAt
        updatedAt
        __typename
      }
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLyriComment = /* GraphQL */ `
  mutation CreateLyriComment(
    $input: CreateLyriCommentInput!
    $condition: ModelLyriCommentConditionInput
  ) {
    createLyriComment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLyriComment = /* GraphQL */ `
  mutation UpdateLyriComment(
    $input: UpdateLyriCommentInput!
    $condition: ModelLyriCommentConditionInput
  ) {
    updateLyriComment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLyriComment = /* GraphQL */ `
  mutation DeleteLyriComment(
    $input: DeleteLyriCommentInput!
    $condition: ModelLyriCommentConditionInput
  ) {
    deleteLyriComment(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      postId
      reports
      replyToUserId
      replyToUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      replyToCommentId
      replyToComment {
        id
        userId
        user {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        postId
        reports
        replyToUserId
        replyToUser {
          id
          isOnCampaign
          premiumUntil
          campaignLikes
          noOfCampaigns
          isPremium
          isPremiumSubscription
          blockedUserIds
          reports
          deviceToken
          email
          picture
          firstName
          lastName
          riddleGuessType
          userRiddleGuesses
          userLikeType
          userLikes
          userPostsType
          userPosts
          userSharesType
          userShares
          createdAt
          updatedAt
          __typename
        }
        replyToCommentId
        replyToComment {
          id
          userId
          postId
          reports
          replyToUserId
          replyToCommentId
          text
          savedAt
          createdAt
          updatedAt
          __typename
        }
        text
        savedAt
        createdAt
        updatedAt
        __typename
      }
      text
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLyriNotification = /* GraphQL */ `
  mutation CreateLyriNotification(
    $input: CreateLyriNotificationInput!
    $condition: ModelLyriNotificationConditionInput
  ) {
    createLyriNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLyriNotification = /* GraphQL */ `
  mutation UpdateLyriNotification(
    $input: UpdateLyriNotificationInput!
    $condition: ModelLyriNotificationConditionInput
  ) {
    updateLyriNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLyriNotification = /* GraphQL */ `
  mutation DeleteLyriNotification(
    $input: DeleteLyriNotificationInput!
    $condition: ModelLyriNotificationConditionInput
  ) {
    deleteLyriNotification(input: $input, condition: $condition) {
      id
      userId
      seen
      notificationCreatedBy
      notificationCreatedByUser {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      notificationType
      notificationData
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserMessageThreads = /* GraphQL */ `
  mutation CreateUserMessageThreads(
    $input: CreateUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    createUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserMessageThreads = /* GraphQL */ `
  mutation UpdateUserMessageThreads(
    $input: UpdateUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    updateUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserMessageThreads = /* GraphQL */ `
  mutation DeleteUserMessageThreads(
    $input: DeleteUserMessageThreadsInput!
    $condition: ModelUserMessageThreadsConditionInput
  ) {
    deleteUserMessageThreads(input: $input, condition: $condition) {
      id
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      lastMessage
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserMessage = /* GraphQL */ `
  mutation CreateUserMessage(
    $input: CreateUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    createUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserMessage = /* GraphQL */ `
  mutation UpdateUserMessage(
    $input: UpdateUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    updateUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserMessage = /* GraphQL */ `
  mutation DeleteUserMessage(
    $input: DeleteUserMessageInput!
    $condition: ModelUserMessageConditionInput
  ) {
    deleteUserMessage(input: $input, condition: $condition) {
      id
      notificationId
      threadId
      senderId
      sender {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      recipientId
      recipient {
        id
        isOnCampaign
        premiumUntil
        campaignLikes
        noOfCampaigns
        isPremium
        isPremiumSubscription
        blockedUserIds
        reports
        devices {
          token
          platform
          __typename
        }
        deviceToken
        email
        picture
        firstName
        lastName
        riddleGuessType
        userRiddleGuesses
        userLikeType
        userLikes
        userPostsType
        userPosts
        userSharesType
        userShares
        createdAt
        updatedAt
        __typename
      }
      message
      imageKey
      savedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
