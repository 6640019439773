import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { Feather, FontAwesome, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";
import { User } from "../models";
import { SheetManager } from 'react-native-actions-sheet';
import UserButton from './user-button';

interface State {
    userLevel?: any
}

interface Props {
    isTopOfTheWeek?: boolean
    hideDetails?: boolean
    user?: User | null
    style?: ViewStyle
}

class UserButtonLong extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    userButton: any;
    tiptop: any = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAErElEQVRoge2YXYgbVRTHo/igtS+tYCv6IuiDj/rkm4jgg4hCbajundlkSWZa90FbhPpSqWCrVeyTgoKiD8Lix4utNZntts1WIevubFG3raAIS2o+9sPddj9pbNPr+U92kpv0zmRmNpl00QsHwty55/z+c8+5cyaRiMfBR/u3c1Ob5OM676ghBsXyyuV5cFPf03H4mgh9T/sFjOtvhCaAYvkGLBzXNxXSyv5CmplkywVDWSCbKBrK4Zk0uy9UAaZ+ADERGwwWC5gsNmX/pa+jdzXAlwaVB4sG+50WcJkVDDZTziY+9AsyfybGyTdf+qHP17pro8kPENOJB6xgrj95Q7nofHPVFs7GF/1A5FOMj3+xwzLy70vAwnB8sRUPmK2dIKV7hYl5mmDTmehmGH7jGuauDMc9AxQE+CACEKsVz1pm7I1QTo3WBKRVtbk2cA1zl4dj3p58WmmADyLgcibekscSQOwRFId9IXeiZ0vzgktGdCvm5s60FjCfid0EH0TA3OlYS56qALYcoR+r9oWbqts+oQxWmT3V2zLw36d7a9C/frkzsADEojXXZSxUvHcLxbxCW8JyNQGG8pCDgNL0kNo6uFkVMU8pAOigAqZPqsjvkozlr5MvPVxPeZaDgONuNVDNO+VAaVBdJecVz4UcXECFnvIqYkpZDKVXOHSOoQZeFar6e9kie5DzPzsuwNT+cGMg3rRQxK9Epk69uI0u/rMmoJJPqY86CjD170LYgWMu8I8R9I01AWXaqXurE4bysbAt55yKmZy/G4KAd2SxwQQ2IVs+qk1On4htp5ybE4rjM6mAMe05t+BXs0l+/quo9CiFYQ73uAtIPit9+gb7vM6nzIG54Ya80bNL2B7k1/v84MHbGwRMvLyFctSxkKfo9HCCtw33uOR/hWcTWxtiEgNYBK4bpbQalYnEW/lQQ7+RVr7BuduURmPuO7CzxQ4k3HZgTIyF2GBoaizfksLXt0p5vanzy9PxukMQ8JqPfPZnprbPjlNMqc8Q7GQDS5odcYWvL2b9eBM27UaKOsyn+E/JB/i4dq39Asjnz7vvRwzxqFzrPK+DyRO8PaZS7HFy9JusjS2PJC62WwCl1gVZWw8GsPiCt8dkJnYn6kJs+GAzVl+ktVUAfDbt+BJigyEQvDjyQ+o9JOJNcjxrB1j2+ZXlZks/9onws4iFmOsGbx4888QdJaPnSdrqoxQoWx5JltcLDx/whaMSvhGj7eCOgkYS2yiVzq9DwAR8hAYsFYGXG/UvAU6db7G2q/DioDbjBQLLeYDP4d5u80oHv9C/mZ7spy7wn/BfGt/st9RA31IylD6cKjKj1iDOeeS2bnM6DnpbKh7+z2Hd5nQcG14A0qM4pMYWz/ZxmRUHWeyWTiEMbu5+3rGI6YOo23yOg59LPELt8HsEuuJyCq1Y99C93eatDYLa5Odjv2ZYk90n/fYOWUBSC95KJLWuQBcG2dPWp57wr15gIx/wBZ/hwAt/hLXb4LvzAgxW6pwAZaoj0Lquv01WJuMhWVnTtMPtFBAmvGUk4Go7BYQKb1soAgYGBnixWAxkWPu/gP+8gI1SAxv7FMKZHLIIxDrkhe1faI9cFs3RzGAAAAAASUVORK5CYII="
    constructor(props: any) {
        super(props);
        this.state = {}

    }

    componentDidMount() {

    }

    render() {

        return (
            <View style={[style.row, style.vcenter, this.props.style, {}]}>
                <UserButton ref={ref => this.userButton = ref} user={this.props.user} size={60} />
                <TouchableOpacity onPress={async () => {
                    this.userButton.onPressMe();
                }} style={[{ marginLeft: 10, flex: 1 }]}>
                    <View style={[style.column, style.fullWidth, { marginLeft: 5 }]}>
                        <View style={[style.row, style.vcenter, style.fullWidth]}>
                            <Text style={[{ fontSize: 18, fontFamily: 'Jost', fontWeight: 'bold' }]}>{this.props.user?.firstName} {this.props.user?.lastName.substr(0, 1)}. </Text>
                            <View style={{ marginRight: 5, flex: 1 }}></View>
                            {this.props.isTopOfTheWeek ? <View style={[style.row, style.vcenter, { marginTop: 0, marginBottom: 0 }]}>
                                <Image style={[{
                                    marginRight: 5,
                                    height: 18,
                                    width: 18,
                                    resizeMode: 'contain'
                                }]} source={{ uri: this.tiptop }}></Image>
                                <Text style={{ color: colors.primaryColor, fontSize: 18, fontFamily: 'Jost', marginBottom: 0, textAlign: "center" }}>Top of the week</Text>
                            </View> : null}
                        </View>
                        <View style={[style.row, style.vcenter, style.fullWidth, { marginLeft: 0 }]}>
                            <FontAwesome5 name={"book"} size={12} color={colors.greaysh} />
                            <Text style={[{ fontSize: 16, fontFamily: 'Jost', marginLeft: 5, marginRight: 15, color: colors.greaysh }]}>{this.props.user?.userPosts || 0}</Text>
                            <FontAwesome name="heart" size={12} color={colors.greaysh} />
                            <Text style={[{ fontSize: 16, fontFamily: 'Jost', marginLeft: 5, marginRight: 15, color: colors.greaysh }]}>{this.props.user?.userLikes || 0}</Text>
                            <Feather name="share-2" size={12} color={colors.greaysh} />
                            <Text style={[{ fontSize: 16, fontFamily: 'Jost', marginLeft: 5, marginRight: 15, color: colors.greaysh }]}>{this.props.user?.userShares || 0}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    };

}

const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});

export default UserButtonLong;
