import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { LyriPost } from "../models";
import { FontAwesome } from "@expo/vector-icons";

interface State {
  lyrics?: string
  currentPlayTime: number
  lyricsArray?: { duration: number, startTime: number, songLine: string }[]
}

interface Props {
  onLayout?: any
  isSimple?: boolean
  style?: ViewStyle
  lyriPost: LyriPost
}

class LyricsPlayer extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  shouldCount: boolean = false;
  public tm: any;

  constructor(props: any) {
    super(props);
    this.state = { currentPlayTime: 0 }

  }

  componentDidMount() {

    //let lyrics = this.props.lyriPost.lyrics.replace(/(Verse 1|\nVerse 1|\nVerse 2|\nVerse 3|\nVerse 4|\nChorus|:)/g, '').replace(/\n/g, ' \n\n');
    let lyrics = this.props.lyriPost.lyrics.replace(/(Verse 1|\nVerse 1|\nVerse 2|\nVerse 3|\nVerse 4|\nChorus|:)/g, '')
    lyrics = lyrics.replace(/(|Verse)/g, '')
    //lyrics = lyrics.replace(/\n\n\n/g, '\n\n');
    lyrics = lyrics.replace(/\n\n/g, '\n');
    //get audio duration
    //setTimeout(() => {
    const audioDuration = this.context.state.audioDuration || 0;
    console.log("audioDuration", audioDuration);
    const lyricsSpaces = lyrics.split(/\n/).filter((a: any) => a.trim() !== '')
    const lyricsLength = audioDuration / lyricsSpaces.length;
    const totalCharacters = lyricsSpaces.reduce((total: number, line: string) => {
      return total + line.length;
    }, 0);
    const totalWords = lyricsSpaces.reduce((total: number, line: string) => {
      return total + line.split(' ').length;
    }, 0);
    let lastDuration = 0;
    const lyricsArray = lyricsSpaces.map((songLine: string, index) => {
      if (this.props.isSimple) {
        return { songLine, duration: 0, startTime: 0 }
      } else {
        const tl = songLine.length;
        let duration = 0
        duration += (audioDuration / totalCharacters) * tl * 0.9;
        duration += 0.3;
        const startTime = lastDuration;
        lastDuration = duration + startTime;
        return { duration: duration, songLine, startTime: startTime }
      }

    });
    this.setState({ lyrics, lyricsArray })
    let start: any = null;
    const curTime = (timestamp: any) => {
      console.log("Playing!!!")

      /*  if (Platform.OS !== 'web') {
         const currentPlayTime = (this.state.currentPlayTime || 0) + 1
         this.setState({ currentPlayTime })
         if (currentPlayTime >= (this.context.state.audioDuration || 0)) {
           clearInterval(this.tm);
         }
 
       } else {
         const currentPlayTime = (this.state.currentPlayTime || 0) + 1
         this.setState({ currentPlayTime })
         if (currentPlayTime >= (this.context.state.audioDuration || 0)) {
           clearInterval(this.tm);
         }
       } */

      if (!start) start = timestamp;
      const elapsed = timestamp - start;

      if (elapsed >= 1000) { // If a second or more has passed
        //console.log('A second has passed');
        start = timestamp;
        this.setState({ currentPlayTime: this.state.currentPlayTime + 1 })
      }
      if (this.state.currentPlayTime >= (this.context.state.audioDuration || 0)) {

      } else {
        if (this.shouldCount) requestAnimationFrame(curTime);
      }

      //console.log("currentPlayTime", this.state.currentPlayTime)

    }
    if (!this.props.isSimple) {
      //this.tm = setInterval(curTime, Platform.OS === 'web' ? 1000 : 1000);
      //curTime();
      this.shouldCount = true;
      this.setState({ currentPlayTime: 0 })
      requestAnimationFrame(curTime);

    }
    //}, 1)

    //lyricsArray = lyrics.split(/\n\n/);
    //lyrics = lyrics.replace(/\n\n\n/g, '\n');
    //lyrics = lyrics.replace(/\n\n/g, '\n');

  }

  reset = () => {
    this.setState({ currentPlayTime: 0 })
    clearInterval(this.tm);
    this.shouldCount = false;
  }

  componentWillUnmount() {
    if (this.tm) clearInterval(this.tm);
    this.context.playSound("", "");
    this.shouldCount = false;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {

  }

  getTrue = (songLine: any) => {

    return this.state.currentPlayTime < songLine.startTime + songLine.duration && this.state.currentPlayTime > songLine.startTime
  }

  render() {

    return (
      <View onLayout={this.props.onLayout} style={[style.fullWidth, style.fullHeight, this.props.style, {
        borderRadius: 0,
        padding: 0,
        marginBottom: 0,
      }]}>
        {this.state.lyricsArray && this.state.lyricsArray.map((songLine: any, index: number) => {
          return (
            <View key={index} style={[style.fullWidth, {
              flexDirection: 'column',
              marginBottom: 0

            }]}>
              {/* {this.getTrue(songLine) ?
                <FontAwesome name="microphone" style={{
                  position: 'absolute',
                  left: 0,

                }} size={20} color={colors.primaryColor}/> : null}*/}
              {index % 4 === 0 && index !== 0 ? <Text style={[{
                width: '100%',
                height: colors.lineHeight
              }]}>{"           "}</Text> : null}
              <Text style={[{
                lineHeight: colors.lineHeight,
                flex: 1,
                fontFamily: 'Jost',
                paddingRight: 5,
                textAlign: 'left',
                fontSize: 16,
                color: this.getTrue(songLine) ? colors.bluePrimary : colors.neutralBlue

              }]}>{songLine.songLine}</Text>
            </View>)
        })}
      </View>
    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default LyricsPlayer;
