import { AntDesign, Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  View,
  FlatList,
  Platform,
  RefreshControl,
  AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import { SheetManager } from "react-native-actions-sheet";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import * as queries from "./../graphql/queries";
import * as myqueries from "./../graphql/my-queries";

import LyriItem from "../components/lyri-item";

interface Props {

}

interface State {
  type: string

}

class HomeScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  itemList: any

  constructor(props: any) {
    super(props);
    this.state = { type: 'public' }
  }

  createPost() {
    if (Platform.OS === "web") {
      SheetManager.show('download_sheet_home');
      return;
    }

    /*if (!this.context.state.user) {
      SheetManager.show('sign_sheet_home');
      return;
    }*/
    /*console.log("create post", this.context.state.user?.reports)*/
    if (this.context.state.user?.reports && this.context.state.user?.reports?.length >= 10) {
      this.context.showToast('You have been reported by too many users for inappropriate behaviour. You can no longer create new posts.', 'error');
      return;
    }
    //this.context.setAppProp({ textDescription: '' })
    SheetManager.show('create_post');
  }

  async componentDidMount() {
    navigationService.props.setHome = true;
  }

  componentDidUpdate(prevPops: any) {

  }

  async getHistory(token?: string) {
    let topOfTheWeek = null;
    if (!token) {
      topOfTheWeek = (await API.graphql({
        query: myqueries.getTopPostsByTime,
        variables: { type: 'weekly', limit: 1, sortDirection: 'DESC' },
      }) as any).data.getTopPostsByTime?.items?.[0]?.lyriPost;
      console.log("topOfTheWeek", topOfTheWeek);
      if (topOfTheWeek) {
        topOfTheWeek.isTopOfTheWeek = true;
      }
    }
    let history = (await API.graphql({
      query: queries.getPostsByTime,
      variables: { type: this.state.type, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getPostsByTime;
    if (topOfTheWeek) {
      history.items.unshift(topOfTheWeek);
    }
    //console.log(history);
    return { items: history.items, nextToken: history.nextToken };
  }

  reportUser() {
  }

  setPrivate(e: any) {
    if (e.type !== this.state.type) {
      this.setState({ type: e.type }, () => {
        this.itemList.refresh();
      })
    }

  }


  render() {
    const busyIndicator = () => {
      if (this.context.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    const renderItem = ({ item, index }: any) => {
      return <LyriItem index={index} isHome={true} onPress={() => {
      }} item={item} />

    }
    return (
      <View style={style.page}>
        <View style={[style.container]}>

          {navigationService.props.RadioButtonRN && this.context.state.isAdmin ?
            <navigationService.props.RadioButtonRN activeColor={colors.primaryColor} box={false} style={{
              flexDirection: "row",
              paddingLeft: 50,
              paddingRight: 20,
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: 10
            }} textColor={colors.textColor} initial={1} textStyle={{
              color: colors.textColor,
              fontSize: 18
            }}
              data={[
                {
                  label: 'Public',
                  type: 'public'
                },
                {
                  label: 'Private',
                  type: 'private'
                }
              ]}
              selectedBtn={(e: any) => {
                console.log("e", e)
                this.setPrivate(e)
              }}
            /> : null}
          <ItemsList ref={ref => {
            this.itemList = ref
          }} cache={false} disableBottomScroll={true} disableRefresh={false} renderItem={renderItem} stateKey={'historyList'} fn={this.getHistory.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.historyList}></ItemsList>


          {this.context.state.historyList?.items?.length ? <TouchableOpacity onPress={this.createPost.bind(this)}
            style={[{
              borderWidth: 0,
              borderColor: colors.bluePrimary,
              alignItems: 'center',
              justifyContent: 'center',
              width: 60,
              position: 'absolute',
              bottom: !this.context.state.isPremium && this.context.state.adData?.hasPostBanner && Platform.OS !== 'web' && this.context.state.checkedStore ? 70 : 10,
              marginLeft: -25,
              left: '50%',
              height: 60,
              backgroundColor: colors.pinkish,
              borderRadius: 120,
            }]}
          >

            <Ionicons name="add" size={40} color={colors.greaysh} />

          </TouchableOpacity> : null}
          {busyIndicator()}
        </View>

      </View>
    )

  }
}

export default HomeScreen


