import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal, Share, Clipboard, Platform } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { AntDesign, Entypo, EvilIcons, Feather, FontAwesome, FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import { SheetManager } from "react-native-actions-sheet";
import { LyriPost } from "../models";
import config from './../aws-exports';

import { API, graphqlOperation } from 'aws-amplify';
import { updateLyriPost } from '../graphql/mutations';
import navigationService from '../services/navigation-service';

interface State {

  numLikes?: number,
}

interface Props {
  notPad?: boolean
  hideCopyPaste?: boolean
  lyri: LyriPost,
  style?: ViewStyle
}

class LyriItemBar extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  reportPost() {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.reportPost(this.props.lyri);
  }
  async tweetPost() {
    this.context.setAppProp({ isBusy: true })
    //this.context.tweetPost(this.props.lyri);
    const remoteUrl = `https://${config.aws_user_files_s3_bucket}.s3.${'us-east-1'}.amazonaws.com/${this.props.lyri.postVideo}`
    console.log("remoteUrl", remoteUrl);
    const localPath = `${navigationService.props.DocumentDirectoryPath}/LyriTunesVideo.mp4`;
    await navigationService.props.downloadFile({ fromUrl: remoteUrl, toFile: localPath }).promise;
    //await CameraModule?.saveToCameraRoll(uri, 'photo')
    try {
      await navigationService.props.CameraRoll.save(localPath, { type: 'video' });
      console.log('Video saved successfully');
    } catch (error) {
      console.error('Failed to save video to camera roll:', error);
    }
    (await API.graphql(graphqlOperation(updateLyriPost, { input: { id: this.props.lyri.id, isPTwetter: true } })) as any).data.updateLyriPost;
    this.context.setAppProp({ isBusy: false })
    this.context.showToast('Video downloaded', 'success');
  }

  deletePost() {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.context.setAppProp({ toDeletePost: this.props.lyri });
    SheetManager.show('delete_post');
    //this.context.deletePost(this.props.lyri);
  }

  async onLike() {
    const lyri = await this.context.likeLyriPost(this.props.lyri);
    this.setState({ numLikes: lyri.numLikes || 0 });
  }

  componentDidMount() {

  }

  async shareMeme() {
    this.context.shareMe(this.props.lyri);
  }

  awaitOneSecond() {
    return new Promise(resolve => setTimeout(resolve, 200));
  }
  onComment() {
    this.context.setAppProp({
      viewPostComments: this.props.lyri
    }, () => {
      SheetManager.show('comments-box');
    })
  }
  copyLyrics() {
    Clipboard.setString(this.props.lyri.lyrics)
    this.context.showToast('Copied lyrics to clipboard', 'success');
  }

  render() {

    return (
      <View style={[this.props.style, style.row, {
        borderTopWidth: 0,
        paddingRight: this.props.notPad ? 0 : 15,
        paddingLeft: this.props.notPad ? 0 : 15,
        borderRadius: colors.borderRadius,
        borderColor: colors.primaryColor,
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'center'
      }]}>
        <TouchableOpacity onPress={this.onLike.bind(this)}>
          {/* {this.context.state.memesLiked?.indexOf(this.props.meme.id) === -1 ?
            <Feather style={{ marginLeft: 10 }} name="heart" size={24} color={colors.iconColor}/> :
            <FontAwesome name="heart" style={{ marginLeft: 10 }} size={24} color={colors.iconColor}/>
          }*/}
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, flexDirection: 'row', height: 30, alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            {!this.props.lyri.numLikes && !this.state.numLikes ?
              <Feather style={{}} name="heart" size={16} color={colors.greaysh} /> :
              <FontAwesome name="heart" style={{}} size={16} color={colors.greaysh} />}
            <Text style={{
              marginLeft: 10,
              fontSize: 14,
              color: colors.textColor
            }}>{this.state.numLikes || this.props.lyri.numLikes || 0}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={this.onComment.bind(this)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <FontAwesome5 name="comment-dots" style={{}} size={16} c color={colors.greaysh} />
            <Text style={{
              marginLeft: 10,
              fontSize: 14,
              color: colors.textColor
            }}>{this.props.lyri.numComments || 0}</Text>
          </View>
        </TouchableOpacity>
        <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
          <Feather name="eye" style={{}} size={16} c color={colors.greaysh} />
          <Text style={{
            marginLeft: 10,
            fontSize: 14,
            color: colors.textColor
          }}>{this.props.lyri.ipLikes?.length || this.props.lyri.numViews || 0}</Text>
        </View>

        <TouchableOpacity onPress={this.reportPost.bind(this, this.props.lyri)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <AntDesign style={{

            }} name="flag" size={16} color={colors.greaysh} />
          </View>
        </TouchableOpacity>
        {(this.context.state.isAdmin || this.props.lyri.userId === this.context.state.user?.id) && <TouchableOpacity onPress={this.deletePost.bind(this, this.props.lyri)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <MaterialIcons style={{
            }} name="delete" size={16} color={this.props.lyri.user?.deviceToken && this.context.state.isAdmin ? colors.bluePrimary : colors.greaysh} />
          </View>
        </TouchableOpacity>}
        {this.context.state.isAdmin && this.props.lyri.postVideo && <TouchableOpacity onPress={this.tweetPost.bind(this, this.props.lyri)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <AntDesign style={{
            }} name="twitter" size={18} color={this.props.lyri.isPTwetter ? colors.purpleColor : colors.iconColor} />
          </View>
        </TouchableOpacity>}
        {/* <View style={{ flex: 1 }}></View> */}
        {!this.props.hideCopyPaste ? <TouchableOpacity onPress={this.copyLyrics.bind(this)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <Feather name="clipboard" size={16} style={{}} color={colors.greaysh} />
          </View>
        </TouchableOpacity> : null}
        <TouchableOpacity onPress={this.shareMeme.bind(this, this.props.lyri)}>
          <View style={{ display: 'flex', padding: 5, paddingLeft: 10, paddingRight: 10, marginLeft: 5, height: 30, flexDirection: 'row', alignItems: 'center', borderRadius: 24, backgroundColor: colors.whiteish }}>
            <Feather name="share-2" style={{}} size={16} color={colors.greaysh} />
          </View>
        </TouchableOpacity>


      </View>
    )
  };

}

const styles = StyleSheet.create({});

export default LyriItemBar;
