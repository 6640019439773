import React, { Component } from "react";
import navigationService from "../services/navigation-service";
import { Alert, AppState, AsyncStorage, BackHandler, Dimensions, Linking, Platform, Share } from "react-native";
import { Device, LyriComment, LyriPost, User } from "../models";
import { InAppBrowser } from "react-native-inappbrowser-reborn";
import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import config from "./../aws-exports";
import * as mutations from "./../graphql/mutations";
import messaging from '@react-native-firebase/messaging';
import publicIP from 'react-native-public-ip';
const CryptoJS = require('crypto-js');


const AWS = require('aws-sdk');
import Lambda from 'aws-sdk/clients/lambda';
import * as queries from "./../graphql/queries";
import {
  createUser,
  createUserPostLikes,
  deleteLyriPost,
  deleteUser,
  updateLyriPost,
  updateRiddlePost,
  updateUser
} from "./../graphql/mutations";
import DeviceInfo from "react-native-device-info";
import { SheetManager } from "react-native-actions-sheet";
import InAppReview from 'react-native-in-app-review';
import { Storage } from "aws-amplify";
import colors from "../constants/colors";
import analytics from "@react-native-firebase/analytics";
import ReactGA from "react-ga4";
///@ts-ignore
import { v4 as uuidv4 } from 'uuid';
import { onCreateLyriNotification } from "../graphql/subscriptions";
export interface IAppState {
  imagePreview?: string;
  hasUnseenNotifications?: boolean;
  adminMessage?: string;
  selectedUser?: User
  viewPostComments?: LyriPost
  isExplicit?: boolean
  publicIp?: string;
  isPromoter: boolean;
  isLikePremium: boolean;
  requestNonPersonalizedAdsOnly: boolean,
  toDeletePost?: any;
  appMenuOpened?: boolean;
  appMenuOpenedBack?: boolean;
  viewPost?: LyriPost
  expandedItem?: any;
  packageName?: any;
  audioDuration?: number;
  currentPlayTime?: number;
  checkedStore?: boolean
  adData?: any
  isAdPlaying?: boolean,
  receivedReward?: boolean
  isAdmin?: boolean;
  playedSoundsNumber?: number;
  generatedSoundsNumber?: number;
  generatedLyricsNumber?: number;
  os?: string
  shareUrl?: string,
  sharePost?: LyriPost,
  hasShownPolicy: string;
  reportUser: any;
  routeParams: any,
  isPlaying: boolean,
  shareModalVisible?: boolean,
  riddlesList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  messageThreads: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  notificationsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  messageList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  commentsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  historyList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  horoscopeList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  myPostsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  newsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  myLikedList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  weekTopsList: { nextToken?: string, items: any[], loading: boolean, refreshing: boolean }
  textDescription?: string;
  lyricsStyle?: string;
  lyricsTone?: string;
  hasChorus?: boolean;
  numVerses?: number;
  audio?: any
  isGeneratingLyrics?: any
  soundtrack?: any
  magicText?: string
  tuneUrl?: string
  lyrics?: string
  user?: User
  isBusy?: boolean
  appConfig?: any
  screen?: any
  isPremium: boolean;
  isPremiumSubscriber: boolean;
  isMobile: boolean;
  isTablet: boolean;
  userPicture: string;
  isPortrait: boolean;
}

export interface IProps {
  Sound: any
  Toast: any
}

export interface IContext {
  state: IAppState,
  hideViewPost: () => void,
  getEmojy: (label: string) => void,
  hideMenu: () => void,
  signOut: () => void,
  gotoStore: () => void,
  getUnseenNotifications: () => void,
  generateVideo: () => void,
  startViewCampaign: () => void,
  waitSomeSeconds: (time: number) => void,
  postComment: (post: LyriPost, comment: string, replyToUser?: any) => void,
  logEvent: (name: string) => void
  gotoNotification: (data: any) => void,
  sendPushNotification: (title: string, message: any, data: any, deviceToken: string) => void,
  reportComment: (comment: LyriComment) => void,
  reportUserAccount: (isBlock?: boolean) => void,
  answerRiddle: (riddle: any, answer: string) => void,
  banUser: () => void,
  deleteUser: () => void,
  buyPremium: () => void,
  sendMail: (emailTo: string, subject: string, message: string) => void,
  appReview: () => void,
  buyPremiumSubscription: (isYearly?: boolean) => void,
  restorePurchase: () => void,
  playRewardedAdAndGenerate: (callback?: any) => void,
  showRewarded: (callback?: any) => void,
  playRewardedAndGo: () => void,
  createAd: (addType: string, callback?: any, onClose?: any) => void,
  updateAccount: (data: any) => void,
  likeLyriPost: (lyriPost: LyriPost) => Promise<LyriPost>,
  tweetPost: (lyriPost: LyriPost) => void,
  shareMe: (lyriPost: LyriPost) => void,
  reportPost: (lyriPost: LyriPost) => void,
  deletePost: (lyriPost: LyriPost) => void,
  playSound: (soundtrack: string, lyrics: string) => void,
  getListItems: (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => void,
  getMagicText: (force?: boolean) => void,
  getCurrentTime: () => number,
  openReportUser: (user?: User) => void,
  setScreen: (screen: string, navigate?: boolean, params?: any, callback?: any) => void
  setAppProp: (obj: any, callback?: any) => void,
  showToast: (message: string, type: 'error' | 'success') => void,

  setUser: (user: any) => void
}

const initialState: any = {
  requestNonPersonalizedAdsOnly: false,
  riddlesList: { items: [], loading: false, refreshing: false },
  messageThreads: { items: [], loading: false, refreshing: false },
  notificationsList: { items: [], loading: false, refreshing: false },
  commentsList: { items: [], loading: false, refreshing: false },
  weekTopsList: { items: [], loading: false, refreshing: false },
  messageList: { items: [], loading: false, refreshing: false, sortKey: 'savedAt' },
  historyList: { items: [], loading: false, refreshing: false },
  horoscopeList: { items: [], loading: false, refreshing: false },
  myPostsList: { items: [], loading: false, refreshing: false },
  myLikedList: { items: [], loading: false, refreshing: false },
  newsList: { items: [], loading: false, refreshing: false },
  appConfig: {},
  numVerses: 3,
  hasChorus: false,
  lyricsStyle: 'Default',
  lyricsTone: 'Default',
  isBusy: false
}

export const AppContext = React.createContext<IContext>(initialState);
export const AppContextProvider = AppContext.Provider

export class AppProvider extends Component<IProps, any> {
  state = initialState
  firstScreen: any
  goRewardedCallback: any;
  doNotPlayAd: boolean = false;
  tm?: any
  openAdTm?: any
  public currentPlayTime: number = 0;
  private canPlay: boolean = false;
  private soundtrack: any;
  private audio: any;
  showedOnceCampaign: boolean = false;

  constructor(props: IProps) {
    super(props);

  }
  waitSomeSeconds = (time: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }
  gotoStore() {
    if (this.state.os === 'ios') {
      //window.open(this.state.appConfig.iOSApp)
      Linking.openURL(this.state.appConfig.iOSApp);

    } else {
      //window.open(this.state.appConfig.androidApp)
      Linking.openURL(this.state.appConfig.androidApp);
    }
  }
  sendBlastNotification = async () => {
    return;
    const devices = await this.getAllDevices();
    const deviceTokens = devices.map((device: any) => device.deviceToken)
    console.log("devices", devices)
    //split the deviceTokens into chucnks of 100 and call the function sendNotificationToDevices for each chunk
    const chunkSize = 100;
    for (let i = 0; i < deviceTokens.length; i += chunkSize) {
      const chunk = deviceTokens.slice(i, i + chunkSize);
      await this.sendNotificationToDevices(chunk);
    }
  }
  getAllDevices = async (nextToken = null) => {
    /*  const result = await API.graphql({
       query: queries.listUsers,
       variables: { limit: 1000, nextToken, filter: { email: { eq: "yonodo10@gmail.com" } } },
     }) as any; */
    const result = await API.graphql({
      query: queries.listUsers,
      variables: { limit: 1000, nextToken },
    }) as any;
    const usersWithDeviceToken = result.data.listUsers.items.filter(
      (user: any) => user.deviceToken !== null && user.deviceToken !== ''
    );

    if (result.data.listUsers.nextToken) {
      const nextUsersWithDeviceToken = await this.getAllDevices(result.data.listUsers.nextToken);
      return usersWithDeviceToken.concat(nextUsersWithDeviceToken);
    }

    return usersWithDeviceToken;
  };
  postComment = async (post: LyriPost, comment: string, replyToUser?: any) => {
    if (comment) {
      await API.graphql(graphqlOperation(mutations.createLyriComment, {
        input: {
          userId: this.state.user.id,
          postId: post.id,
          replyToUserId: replyToUser ? replyToUser.id : null,
          reports: [],
          text: comment,
          savedAt: new Date().toISOString()
        }
      }))
      API.graphql(graphqlOperation(updateLyriPost, {
        input: {
          id: post.id,
          numComments: (post.numComments || 0) + 1
        }
      }));
      if (post.userId !== this.state.user.id) {
        const notification = (await API.graphql(graphqlOperation(mutations.createLyriNotification, {
          input: {
            userId: post.userId,
            seen: false,
            notificationType: "comment",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: post.id,
              comment: comment
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createLyriNotification;
        if (post.user.deviceToken) {
          this.sendPushNotification("New Comment", `${this.state.user.firstName} commented on your post: "${comment.substr(0, 20)}..."`, { postId: post.id, notificationType: "comment", notId: notification.id }, post.user.deviceToken)
        }
      }
      if (replyToUser) {
        const replyNotification = (await API.graphql(graphqlOperation(mutations.createLyriNotification, {
          input: {
            userId: replyToUser.id,
            seen: false,
            notificationType: "comment-reply",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: post.id,
              comment: comment
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createLyriNotification;
        if (replyToUser.deviceToken) {
          this.sendPushNotification("Comment Reply", `${this.state.user.firstName} replied to your comment: "${comment.substr(0, 20)}..."`, { postId: post.id, notificationType: "comment-reply", notId: replyNotification.id }, replyToUser.deviceToken)
        }
      }
      this.logEvent("post_comment");
    }

  }
  reportComment = async (comment: LyriComment) => {
    const userId = this.state.user ? this.state.user.id : null;
    try {
      const reports = comment.reports || [];
      if (!reports.includes(userId)) {
        reports.push(userId);
        const toSaveComment = {
          id: comment.id,
          reports
        }
        await API.graphql(graphqlOperation(mutations.updateLyriComment, { input: toSaveComment }))
        this.showToast("Reported", "success");
      } else {
        this.showToast("Already reported", "error");
      }
    } catch (e) {

    }

  }
  gotoNotification = async (data: any) => {
    //console.log("kaka", data)
    try {
      switch (data.notificationType) {
        case 'admin':
          this.setState({ adminMessage: data.message }, () => {
            SheetManager.show('admin_message_sheet');
          });
          break;
        case 'comment':
          const post = (await API.graphql({ query: queries.getLyriPost, variables: { id: data.postId } }) as any).data.getLyriPost;
          //console.log("post", post)
          if (post) {
            await this.waitSomeSeconds(100);
            this.setAppProp({
              viewPostComments: post
            }, () => {
              SheetManager.show('comments-box');
            })
          }
          break;
        case 'comment-reply':
          const replyPost = (await API.graphql({ query: queries.getLyriPost, variables: { id: data.postId } }) as any).data.getLyriPost;
          //console.log("replyPost", replyPost)
          if (replyPost) {
            await this.waitSomeSeconds(100);
            this.setAppProp({
              viewPostComments: replyPost
            }, () => {
              SheetManager.show('comments-box');
            })
          }
          break;
        case 'like':
          const postLike = (await API.graphql({ query: queries.getLyriPost, variables: { id: data.postId } }) as any).data.getLyriPost;
          //console.log("postLike", postLike)
          if (postLike) {
            await this.waitSomeSeconds(100);
            this.setAppProp({
              viewPostComments: postLike
            }, () => {
              SheetManager.show('comments-box');
            })
          }
          break;
        case 'message':
          try {
            if (!data.notificationCreatedByUser) {
              data.notificationCreatedByUser = (await API.graphql({ query: queries.getUser, variables: { id: data.userId } }) as any).data.getUser;
            }
            if (data.notificationCreatedByUser) {
              this.setAppProp({
                selectedUser: data.notificationCreatedByUser
              }, () => {
                this.setScreen('Message', true);
              })

            }
          } catch (e) {
          }
          break;
      }
      this.seenNotificationById(data.notId);
    }
    catch (e) {
      console.log("error", e)
    }

  }
  async seenNotificationById(id: any) {
    await API.graphql(graphqlOperation(mutations.updateLyriNotification, {
      input: {
        id: id,
        seen: true
      }
    }));
    this.getUnseenNotifications();
  }
  getUnseenNotifications = async () => {
    if (!this.state.user) return;
    let notifications = (await API.graphql({
      query: queries.getNotificationsByTime,
      variables: { userId: this.state.user?.id, limit: 10, sortDirection: 'DESC', filter: { seen: { eq: false } } },
    }) as any).data.getNotificationsByTime
    notifications.items = notifications.items.filter((item: any) => item.notificationCreatedByUser);
    const hasUnseenNotifications = notifications.items.length !== 0;
    //console.log("hasUnseenNotifications", hasUnseenNotifications)
    //console.log("hasUnseenNotifications", notifications)
    this.setState({ hasUnseenNotifications });
  }
  sendNotificationToDevices = async (devices: any) => {
    try {
      const result = (await API.post('lyritunesrest', '/rest/send-blast-push-notification/', {
        body: {
          devices,
          title: "LyriTunes, Hello World",
          path: "6e8cddbd-1a1e-40cf-8a1c-504053a540c4",
          message: "Riddles, Horoscope, News, Explicit Lyrics and more...Enjoy! 🎉",
        }
      }));
      console.log("result", JSON.stringify(result));
    } catch (e) {
      console.log("error", e)
    }
  }
  sendNewsletter = async () => {

    config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002/"
    Amplify.configure(config)
    const message = `
       `;
    const result = await API.post('lyritunesrest', '/rest/send-blast-email', {
      body: { subject: "LyriTunes - You asked for it; we delivered", message, isTest: false, templateId: "LyriTunesTemplate4" }
    })
    console.log("result", result)
  }
  uploadMusic = async () => {
    config.aws_appsync_authenticationType = "API_KEY";
    config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002"
    Amplify.configure(config);
    const result = await API.post('lyritunesrest', '/rest/upload-music/', {
      body: {
        category: "inspiring"
      }
    })
    console.log("Done")



  }

  async componentDidMount() {

    const ip = await publicIP();
    this.setState({ publicIp: ip });
    //console.log("ip", ip)
    //this.sendBuyMail();

    //await this.uploadMusic();
    //this.sendNewsletter()
    /*  this.sendBlastNotification();
     return; */
    if (Platform.OS === 'web') {
      window.addEventListener("resize", this.checkOrientation.bind(this));
      this.checkOrientation();
    } else {

      const authStatus = await messaging().requestPermission();
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL;
      console.log("fcm enabled", enabled)
      if (enabled) {

      }
      try {

        messaging().setBackgroundMessageHandler(async remoteMessage => {
          console.log('Message handled in the background!', remoteMessage);
          if (!navigationService.props.initialNotification) {
            this.gotoNotification(remoteMessage.data);
          }
          // Handle the message here, e.g., show a notification or update the app's UI
        });
      } catch (e) {
        console.log("error", e)
      }
      /*  if (Platform.OS === 'ios') {
         try {
           messaging().onMessage(async remoteMessage => {
             console.log('Message handled in the foreground!', remoteMessage);
             this.gotoNotification(remoteMessage.data);
             // Handle the message here, e.g., show a notification or update the app's UI
           });
         } catch (e) {
           console.log("error", e)
         }
       } */
      if (Platform.OS === 'android') {
        this.listenBackButton();
      }
      Dimensions.addEventListener('change', () => {
        this.checkOrientationMobile();
      });
      this.checkOrientationMobile();
    }
    this.getAppConfig();
  }

  isPortrait = () => {
    const dim = Dimensions.get('window');
    return dim.height >= dim.width;
  };
  getAd = (addType: string, callback?: any) => {
    let ad;
    console.log("getAd", addType, this.state.adData[addType])
    switch (addType) {
      case "re":
        ad = navigationService.props.RewardedAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "in":
        ad = navigationService.props.InterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "inre":
        ad = navigationService.props.RewardedInterstitialAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
      case "op":
        ad = navigationService.props.AppOpenAd.createForAdRequest(this.state.adData[addType], {
          requestNonPersonalizedAdsOnly: this.state.requestNonPersonalizedAdsOnly
        });
        break;
    }
    return ad;
  }
  createAd = (addType: string, callback?: any, onClose?: any) => {
    let ad: any;

    if (Platform.OS === "web" || this.state.isPremium) return;
    if (addType === 'open') {
      ad = this.getAd(this.state.adData['openType']);
    } else {
      ad = this.getAd(addType);
    }
    //console.log("createAd", addType, ad)

    try {
      ad.addAdEventListener('rewarded_loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true, receivedReward: false })
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('loaded', () => {
        ad.show();
        this.doNotPlayAd = true;
        this.setState({ isAdPlaying: true });
        if (callback) callback(ad);
      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('closed', () => {

        this.setPlayAd(false)
        this.setState({ isAdPlaying: false })
        if (onClose && (addType === "in" || addType === "op")) {
          onClose()
        };

      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('error', (e: any) => {
        console.log("error", e)
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })


      });
    } catch (e) {
    }
    try {
      ad.addAdEventListener('rewarded_earned_reward', () => {
        this.setPlayAd(false)
        this.setState({ isAdPlaying: false, receivedReward: true }, () => {
          if (onClose) onClose();
        })

      });
    } catch (e) {
    }
    ad.load();
    return ad;
  }
  listenBackButton = () => {
    const me = this;
    const backHandler = BackHandler.addEventListener('hardwareBackPress', function () {
      console.log("back pressed", me.state.screen)
      if (me.state.viewPost) {
        //me.setState({ viewPost: undefined })
        me.hideViewPost();
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else if (me.state.appMenuOpened) {
        me.hideMenu();
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else if (me.state.imagePreview) {
        me.setState({ imagePreview: null });
        backHandler.remove();
        me.listenBackButton();
        return true;
      } else {
        if ((me.state.screen === 'Home' || !me.state.screen)) {
          //throw {};
          backHandler.remove();
          me.listenBackButton();
          me.playSound("", "")
          BackHandler.exitApp();
          return true;
        }
      }
      if (!navigationService.props.setHome && !me.state.viewPost && !me.state.appMenuOpened) {
        //(store.dispatch as ThunkDispatch<any, void, ActionTypes>)(setScreen('Home', {}, false));
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
        backHandler.remove();
        me.listenBackButton();
        return true;
      }



      return false;
    });
  }
  showRewarded = (callback?: any) => {
    this.goRewardedCallback = callback;
    SheetManager.show('go_ad_view');
  }
  playRewardedAndGo = () => {
    this.playRewardedAdAndGenerate(this.goRewardedCallback);
  }
  playRewardedAdAndGenerate = (callback?: any) => {
    this.createAd('re', (ad: any) => {
      ad.show();
    }, () => {
      if (this.state.receivedReward) {
        if (!callback) {
          this.getMagicText(true);
        } else {
          callback();
        }

      } else {
        this.showToast("You did not get a reward, please wait until the ad finishes playing and the reward is received!", "error")
      }
    })
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  banUser = async () => {
    const user = this.state.selectedUser || this.state.reportUser;
    let ids = [];
    //generate 12 random ids with udid
    for (let i = 0; i < 12; i++) {
      ids.push(uuidv4());
    }
    const reports = ids;
    const toSaveUser = {
      id: user.id,
      reports
    }
    const savedUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
    user.reports = reports;
    this.showToast("User banned", "success")

  }
  reportUserAccount = async (isBlocked?: boolean) => {

    const user = this.state.selectedUser || this.state.reportUser;
    const userId = this.state.user.id;

    if (!user) {
      const devId = DeviceInfo.getDeviceId();
      const result = (await API.put('lyritunesrest', '/rest/flag-user/', {
        body: {
          devId
        }
      }));
      this.showToast("Reported user as inappropriate", "success")
    } else {
      try {
        const reports = user.reports || [];
        if (!reports.includes(userId)) {
          reports.push(userId);
          const toSaveUser = {
            id: user.id,
            reports
          }
          const savedUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
          user.reports = reports;
          //update the user in historyList
          if (isBlocked) {

            const historyList = { ...this.state.historyList }
            historyList.items = historyList.items.map((item: any) => {
              if (item.user && item.user?.id === user.id) {

                item.user = user;
              }
              return item;
            });
            historyList.items = historyList.items.filter((item: any) => {
              const postReports = item.reports?.length || 0;
              const userReports = item.user?.reports?.length || 0;
              const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
              return postReports < 10 && userReports < 10 && !isBlockedByMe;
            })
            this.setState({ historyList })

            const myBlockedUsers = this.state.user.blockedUserIds || [];
            if (!myBlockedUsers.includes(user.id)) {
              myBlockedUsers.push(user.id);
              const newUser = (await API.graphql(graphqlOperation(updateUser, { input: { id: this.state.user.id, blockedUserIds: myBlockedUsers } })) as any).data.updateUser;
              this.setState({ user: newUser });
            }
          }


          this.showToast(isBlocked ? "User successfully blocked" : "Reported user as inappropriate", "success")
        } else {
          //error({ message: "Already reported" });
          this.showToast(isBlocked ? "Already blocked" : "Already reported", "error")

        }
      } catch (e) {
        console.log("report user error", e)
      }
    }

  }
  openReportUser = (user?: User) => {
    // if (!user) return;
    if (!this.state.user) {
      this.setScreen('Sign In', true);
      return;
    }
    if (user?.id === this.state.user.id) {
      //this.showToast("You can't report yourself", "error");
      return;
    }

    this.setState({ reportUser: user }, () => {
      SheetManager.show('user_menu_report')

    });
  }
  getListItems = async (stateKey: any, fn: any, nextTokenName: string, refresh: boolean) => {
    const obj = { ...this.state[stateKey] }
    if (!refresh && !obj.nextToken) return;
    obj.loading = !obj.items.length;
    obj.refreshing = refresh && obj.items.length !== 0;
    if (refresh) {
      obj.items = [];
    }
    this.setState({ [stateKey]: { ...obj } });
    let items = refresh ? await fn() : await fn(obj.nextToken);
    obj.items = refresh ? items.items : obj.items.concat(items.items)
    //make sure obj.items contains unique objects with videoId
    obj.items = obj.items.filter((item: any) => {
      const postReports = item.reports?.length || 0;
      const userReports = item.user?.reports?.length || 0;
      const isBlockedByMe = item.user?.reports?.includes(this.state.user?.id);
      return postReports < 10 && userReports < 10 && !isBlockedByMe;
    })
    if (obj.sortKey) {
      obj.items = obj.items.sort((a: any, b: any) => {
        return new Date(a[obj.sortKey]).getTime() - new Date(b[obj.sortKey]).getTime();
      });
    }
    obj.nextToken = items[nextTokenName]
    obj.loading = false;
    obj.refreshing = false;
    this.setState({ [stateKey]: obj });
  }

  checkOrientationMobile() {
    const orientation = this.isPortrait() ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      isMobile: true,
      isTablet: DeviceInfo.isTablet(),
      isPortrait: orientation === "portrait"
    });
  }

  sendPushNotification = async (title: string, message: any, data: any, deviceToken: string) => {
    //console.log("user", user)
    if (deviceToken) {
      //console.log("user.devices", user?.deviceToken)
      try {
        const result = (await API.post('lyritunesrest', '/rest/send-new-notification/', {
          body: {
            device: deviceToken,
            title,
            data,
            message
          }
        }));
        console.log("result", JSON.stringify(result));
      } catch (e) {
        console.log("error", e)
      }
    }
  }
  answerRiddle = async (riddle: any, answer: string) => {
    //const ip = await publicIP();
    const bytes = CryptoJS.AES.decrypt(riddle.correctAnswer, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369kakaiaiaia");
    const correctAnswer = bytes.toString(CryptoJS.enc.Utf8);
    console.log("onCorrectAnswer", answer, correctAnswer);
    const newRiddle = (await API.graphql({
      query: queries.getRiddlePost,
      variables: { id: riddle.id }
    }) as any).data.getRiddlePost;
    console.log("newRiddle", newRiddle)
    let updateRiddle: any = { id: riddle.id };
    if (answer.toLowerCase() === correctAnswer.toLowerCase()) {
      updateRiddle.correctAnswers = (newRiddle.correctAnswers || 0) + 1;
      this.showToast("Correct answer", "success");
      this.logEvent("riddle_correct");
      if (this.state.user) {
        const toSaveUser = {
          id: this.state.user.id,
          riddleGuessType: "riddlesType",
          userRiddleGuesses: (this.state.user.userRiddleGuesses || 0) + 1
        }
        const newUser = (await API.graphql(graphqlOperation(updateUser, { input: toSaveUser })) as any).data.updateUser;
        this.setState({ user: newUser });
      }
    } else {
      updateRiddle.wrongAnswers = (newRiddle.wrongAnswers || 0) + 1;
      this.showToast("Wrong answer", "error");
      this.logEvent("riddle_wrong");
    }
    updateRiddle.ipAnswers = updateRiddle.ipAnswers || [];
    updateRiddle.ipAnswers.push(this.state.publicIp);
    const savedRiddle = (await API.graphql(graphqlOperation(updateRiddlePost, { input: updateRiddle })) as any).data.updateRiddlePost;
    //update this in riddle in state for the riddlesList array
    const riddlesList = { ...this.state.riddlesList }
    const index = riddlesList.items.findIndex((item: any) => item.id === savedRiddle.id);
    if (index !== -1) {
      riddlesList.items[index] = savedRiddle;
      this.setState({ riddlesList });
    }


  }
  likeLyriPost = async (lyriPost: any) => {

    if (!this.state.user) {
      this.setScreen("Sign In", true);
      return;
    }
    const lyriuserid = `${this.state.user.id}${lyriPost.id}`
    const liked = (await API.graphql({
      query: queries.getUserPostLikes,
      variables: { id: lyriuserid }
    }) as any).data.getUserPostLikes;
    //console.log("liked", liked)
    if (liked) {
      //error({ message: "Already liked" });
      this.showToast("Already liked", "error")
      return lyriPost;
    }
    this.showToast("Liked", "success");

    const like = {
      id: lyriuserid,
      userId: this.state.user.id,
      postId: lyriPost.id,
      savedAt: new Date().toISOString()
    }
    try {

      (await API.graphql(graphqlOperation(createUserPostLikes, { input: like })) as any).data.createUserPostLikes;
      const numLikes = (lyriPost.numLikes || 0) + 1;
      const toSavePost = {
        id: lyriPost.id,
        numLikes
      }
      const savedLyriPost = (await API.graphql(graphqlOperation(updateLyriPost, { input: toSavePost })) as any).data.updateLyriPost;
      //update the numLikes in history state

      const historyList = { ...this.state.historyList }
      const index = historyList.items.findIndex((item: any) => item.id === savedLyriPost.id);
      if (index !== -1) {
        historyList.items[index] = savedLyriPost;
        this.setState({ historyList });
      }
      if (lyriPost.user.id) {
        const toSaveUser = {
          id: lyriPost.user.id,
          userLikes: (lyriPost.user.userLikes || 0) + 1
        }
        await API.graphql(graphqlOperation(updateUser, { input: toSaveUser }));
      }
      if (lyriPost.userId !== this.state.user.id) {
        const notification = (await API.graphql(graphqlOperation(mutations.createLyriNotification, {
          input: {
            userId: lyriPost.userId,
            seen: false,
            notificationType: "like",
            notificationCreatedBy: this.state.user.id,
            notificationData: JSON.stringify({
              postId: lyriPost.id
            }),
            savedAt: new Date().toISOString()
          }
        })) as any).data.createLyriNotification;
        if (lyriPost.user.deviceToken) {
          this.sendPushNotification("Post Like", `${this.state.user.firstName} like your post`, { postId: lyriPost.id, notificationType: "like", notId: notification.id }, lyriPost.user.deviceToken)
        }
      }
      return savedLyriPost;
    } catch (err) {
      console.log("error creating like", err)
    }

  }

  checkOrientation() {
    let os;
    if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
      os = "android";
    } else if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      os = "ios";
      console.log("IOS")
    } else {
      os = "kaka";
    }
    const orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    this.setState({
      orientation,
      os,
      isMobile: this.detectMob(),
      isTablet: window.innerWidth > 1024,
      isPortrait: orientation === 'portrait'
    });

  }

  generateVideo = async () => {
    /*config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002"
    Amplify.configure(config)*/
    try {
      const result = (await API.post('lyritunesrest', '/rest/generate-video/', {
        body: {
          lyrics: this.state.lyrics,
        }
      }));
      let generatedSoundsNumber = 0;
      if (Platform.OS !== "web") {
        generatedSoundsNumber = parseInt(await AsyncStorage.getItem("generatedSoundsNumber") || "0") || 0;
        generatedSoundsNumber++;
        AsyncStorage.setItem("generatedSoundsNumber", generatedSoundsNumber.toString());

      }
      this.setState({ tuneUrl: result.url, generatedSoundsNumber });
      this.logEvent("generate_song");
      console.log("url", result.url)
    } catch (e) {
      console.log("error", e)
    }
  }
  reportPost = async (lyriPost: LyriPost) => {
    const userId = this.state.user ? this.state.user.id : null;
    try {
      const reports = lyriPost.reports || [];
      if (!reports.includes(userId)) {
        reports.push(userId);
        const toSaveMeme = {
          id: lyriPost.id,
          reports
        }
        const savedMeme = (await API.graphql(graphqlOperation(updateLyriPost, { input: toSaveMeme })) as any).data.updateLyriPost;
        this.showToast("Reported", "success");
      } else {

        this.showToast("Already reported", "error");
      }
    } catch (e) {

    }
  }
  deletePost = async (lyriPost: LyriPost) => {
    try {
      this.setState({ isBusy: true });
      const result = (await API.graphql(graphqlOperation(deleteLyriPost, { input: { id: lyriPost.id } })) as any).data.deleteLyriPost;
      //update the history state
      const historyList = { ...this.state.historyList }
      const index = historyList.items.findIndex((item: any) => item.id === result.id);
      if (index !== -1) {
        historyList.items.splice(index, 1);
        this.setState({ historyList });
      }
      const myPostsList = { ...this.state.myPostsList }
      const index2 = myPostsList.items.findIndex((item: any) => item.id === result.id);
      if (index2 !== -1) {
        myPostsList.items.splice(index2, 1);
        this.setState({ myPostsList });
      }
      this.showToast("Deleted post!", "success");
      this.setState({ isBusy: false });
    } catch (e) {

      //this.showToast("Already reported", "error");
    }

  }


  tweetPost = async (lyriPost: LyriPost) => {
    this.setState({ isBusy: true });
    if (lyriPost.isPTwetter) {
      this.showToast("Already tweeted", "error");
      this.setState({ isBusy: false });
      return;
    }
    let hashtags = await this.openai(`Get 5 relevant twitter post hashtags written in lowercase and separated by space from this text "${lyriPost.title}"`, 800);
    console.log("hashtags", hashtags)
    //return;
    if (hashtags.indexOf("_true_") !== -1) {
      this.showToast("Hashtags error", "error");
      this.setState({ isBusy: false });
      return;

    }
    hashtags += " #lyritunes"
    let tweet = `${hashtags} https://preview.lyritunes.com/p/?m=${lyriPost.id}&v=${Date.now()}`
    console.log("tweet", tweet)
    //replace tweet " with nothing
    tweet = tweet.replace(/"/g, "");
    //return
    try {
      //make a fetch post request to https://vvvdgjzn49.execute-api.us-east-1.amazonaws.com/prod/twitter
      await fetch('https://vvvdgjzn49.execute-api.us-east-1.amazonaws.com/prod/twitter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: tweet
      })


      //(await API.post('lyritunesrest', '/twitter', { body: tweet }));

    } catch (e) {
      this.showToast(e.message, "error");
      this.setState({ isBusy: false });
      return;
    }
    (await API.graphql(graphqlOperation(updateLyriPost, { input: { id: lyriPost.id, isPTwetter: true } })) as any).data.updateLyriPost;
    this.showToast("Tweeted!", "success");
    lyriPost.isPTwetter = true;
    this.setState({ isBusy: false });
  }
  shareMe = async (lyriPost: LyriPost) => {
    const url = `https://preview.lyritunes.com/p/?m=${lyriPost.id}&v=${Date.now()}`;
    console.log("url", url)
    if (Platform.OS === "web") {
      if (!this.state.isMobile) {
        this.setState({ shareModalVisible: true, shareUrl: url, sharePost: lyriPost })
      } else {
        navigator.share({ url });
      }

    } else {
      this.setPlayAd(true);
      await Share.share({
        title: lyriPost.title,
        message: url,
        url: url
      });
      this.setPlayAd(false);

      if (this.state.user?.id) {
        this.updateAccount({ userShares: (this.state.user.userShares || 0) + 1 })
      }
    }
    if (lyriPost.socialType !== "instanews") {
      this.logEvent("share");
    } else {
      this.logEvent("share_instanews");
    }
  }
  openai = async (prompt: string, max_tokens: number, isExplicit?: boolean): Promise<any> => {
    const credentials = await Auth.currentCredentials()
    AWS.config.update({
      region: 'us-east-1',
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey
    });
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      httpOptions: {
        timeout: 15 * 60 * 1000
      }
    });
    return new Promise((resolve, reject) => {
      lambda.invoke({
        FunctionName: 'lyritunesopenai-prod',
        Payload: JSON.stringify({ prompt, max_tokens, isExplicit }),
      }, (err, data: any) => {
        if (err) {
          reject(err);
          this.showToast("Error generating lyrics", "error");
        } else {
          try {
            resolve(JSON.parse(data.Payload).body.text);
          } catch (e) {
            reject(new Error("Error generating lyrics"))
            this.showToast("Error generating lyrics", "error");
          }
        }
      });
    })
  }
  startViewCampaign = async () => {
    const acc = (await API.graphql(graphqlOperation(updateUser, { input: { id: this.state.user.id, campaignLikes: 0, premiumUntil: 0, isOnCampaign: true, noOfCampaigns: (this.state.user.noOfCampaigns || 0) + 1 } }))).data.updateUser;
    this.setState({ user: acc });
    console.log("acc", acc)
    this.showToast("Your views campaign has started!", "success");
    this.logEvent("start_campaign");
    this.sendMail("cristian.anderes.radu@gmail.com", "LyriTunes - Start Campaign", this.state.user.email);
  }
  getMagicText = async (force?: boolean) => {
    if (this.state.isGeneratingLyrics) return;
    this.setState({ magicText: "", lyrics: "" });
    let text = this.state.textDescription || 'A cat named Tom and a clever mouse named Jerry as they try to outwit each other'

    console.log("textu", text)
    if (text.length < 3) {
      this.showToast("Please enter a description", "error");
      return;
    }
    let generatedLyricsNumber = 0;
    if (Platform.OS !== 'web') generatedLyricsNumber = parseInt(await AsyncStorage.getItem("generatedLyricsNumber") || "0") || 0;
    if (!this.state.isPremium && !force && generatedLyricsNumber !== 0 && generatedLyricsNumber % (this.state.adData?.genBetween - (this.state.user?.isOnCampaign ? 1 : 0)) === 0 && this.state.adData?.enabled) {

      await SheetManager.hide('create_post');
      setTimeout(() => {
        SheetManager.show("go_ad");
      }, 100)

      return;
    }
    if (force) {
      SheetManager.show("create_post");
    }
    this.setState({ isGeneratingLyrics: true })

    /*try {
      const isViolating = await this.openai(this.state.appConfig.violation.replace(/{token}/gi, text), 100);
      console.log("isViolating", isViolating)
      if (isViolating.toLowerCase().indexOf("true") !== -1) {
        this.showToast("Please do not use obscene words!", "error");
        this.setState({ isGeneratingLyrics: false })
        return;

      }

      /!*correctText = await this.openai(this.state.appConfig.magicText.replace(/{token}/gi, text), 800)

      if (correctText.toLowerCase().indexOf("_true_") !== -1) {
        this.showToast("Please do not use obscene words!", "error");
        return;
      }*!/

    } catch (e) {
      this.showToast("Please try again later", "error");
      return e;
    }*/
    //replace all " with nothing
    /* correctText = correctText.replace(/"/g, "")
     let firstCharTxt = correctText.substring(0, 1);
     if (firstCharTxt === "\n") {
       correctText = correctText.substring(1);
     }
     firstCharTxt = correctText.substring(0, 1);
     if (firstCharTxt === "\n") {
       correctText = correctText.substring(1);
     }
     console.log("correctText", correctText)
     return;*/

    if (Platform.OS !== "web") {
      generatedLyricsNumber = parseInt(await AsyncStorage.getItem("generatedLyricsNumber") || "0") || 0;
      generatedLyricsNumber++;
      AsyncStorage.setItem("generatedLyricsNumber", generatedLyricsNumber.toString());

    }

    //Write the lyrics to a rap song about “Time”. The verses should have no more than 4 lines and each verse without chorus. The entire song should have 3 verses. The lyrics should not contain Bridge or Outro
    this.setState({ generatedLyricsNumber });
    /* config.aws_cloud_logic_custom[0].endpoint = "http://127.0.0.1:3002"
    Amplify.configure(config) */
    let textus = this.state.appConfig.lyricsText2.replace("5 verses", `${this.state.numVerses} verses`);
    if (this.state.hasChorus) {
      textus = textus.replace("each verse", "each verse and chorus");
    } else {
      textus = textus.replace("each verse", "each verse without chorus");
    }
    let magicText;
    try {

      if (this.state.lyricsStyle === "Default") {
        textus = textus.replace("{token}", text)
      } else {
        textus = textus.replace("{token}", text) + this.state.appConfig.lyricsStyle.replace("{token}", this.state.lyricsStyle)

      }
      if (this.state.lyricsTone !== "Default") {
        textus = textus + " " + this.state.appConfig.tone.replace("{token}", this.state.lyricsTone)
      }
      console.log("textus", textus);

      magicText = await this.openai(textus, 800, this.state.isExplicit)

      if (magicText.toLowerCase().indexOf("_true_") !== -1) {
        this.showToast("Please do not use obscene words!", "error");
        this.setState({ isGeneratingLyrics: false })
        return;

      }
    } catch (e) {
      console.log("e", e)
      this.showToast("Please try again later", "error");
      this.setState({ isGeneratingLyrics: false })
      return e;
    }
    this.logEvent("generate_lyrics");
    console.log("magicText", magicText)
    //return;
    const firstChar = magicText.substring(0, 1);
    const secondChar = magicText.substring(1, 2);
    if (firstChar === "\n" && secondChar === "\n") {
      magicText = magicText.substring(2);
    }
    let lyrics = magicText.replace(/(Verse 1|\nVerse 1|\nVerse 2|\nVerse 3|\nVerse 4|\nChorus|:)/g, '').replace(/\n/g, ' \n\n');
    lyrics = lyrics.replace(/(|Verse)/g, '')
    console.log("lyrics", lyrics)
    SheetManager.hide('create_post');
    this.setState({ magicText, lyrics, isGeneratingLyrics: false }, () => {
      this.setScreen("Post", true);
    })

    /* if (generatedLyricsNumber === 3 || generatedLyricsNumber === 12) {
      this.appReview();
    }
 */
  }

  getAppConfig = async () => {
    let appConfig = (await API.get('lyritunesrest', '/rest/configdt?isNew=true', {})).config;
    const bytes = CryptoJS.AES.decrypt(appConfig, "kXp2s5v8y/B?E(G+KbPeShVmYq3t6w9z369kaka");
    appConfig = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //console.log("appConfig", appConfig)
    appConfig.secAndroidPurchase = false;
    if (Platform.OS === "web") {
      appConfig.showPromoters = true;
    }



    appConfig.allowSubscription = appConfig["allowSubscription" + Platform.OS.toString()];
    let adData = Platform.OS !== 'web' ? appConfig.adds[Platform.OS.toString()] : {};
    console.log("adData", adData)
    //adData.banSkip = adData.banSkip + 1;
    //appConfig.showPromoters = false;
    /*appConfig.violation = `Return "_true_" if the text "{token}" contains obscene words`;
    appConfig.magicText = `Return the english translation for "{token}"`;
    appConfig.lyricsText = `Write the lyrics to a hip hop song in a Verse-Chorus Form with nursery rhymes about "{token}"`;*/
    let hasShownPolicy = Platform.OS !== "web" ? (await AsyncStorage.getItem("hasShownPolicy2")) : await localStorage.getItem("hasShownPolicy2");
    hasShownPolicy = hasShownPolicy ? 'true' : 'false';
    this.setState({ appConfig, adData }, () => {
      this.initStore();
    });
    if (this.state.screen !== "View") {
      setTimeout(() => {
        this.setState({ hasShownPolicy });
      }, 500);
    }

    //console.log("appConfi", appConfig)
    if (Platform.OS !== 'web') {
      let version = DeviceInfo.getVersion();
      //construct the build number based on version string like for example 0.1.0 will be 10
      let iosBuildNumber = parseInt(version.split(".").join(""));
      let buildNumber = Platform.OS === 'android' ? parseFloat(DeviceInfo.getBuildNumber()) : iosBuildNumber;
      const upVersion = appConfig.versions[Platform.OS];
      if (upVersion > buildNumber) {
        this.showUpdateAlert(appConfig);
      }
      console.log("upVersion", buildNumber, upVersion)
    }
    let generatedLyricsNumber = 0;
    if (Platform.OS !== "web") {
      generatedLyricsNumber = parseInt(await AsyncStorage.getItem("generatedLyricsNumber") || "0") || 0;
    }
    //Write the lyrics to a rap song about “Time”. The verses should have no more than 4 lines and each verse without chorus. The entire song should have 3 verses. The lyrics should not contain Bridge or Outro
    this.setState({ generatedLyricsNumber });
  }
  initStore = async () => {
    if (Platform.OS === "web") {
      this.setState({ checkedStore: true });
      return;
    }
    let isPremium = false;
    let isPremiumSubscriber = false;
    const prodId = Platform.OS === "android" ? "com.lyritunes.premium" : "com.lyritunes.premium2";
    const prodSubId = Platform.OS === "android" ? "com.lyritunes.premiumsub" : "com.lyritunes.premiumsub4";
    const predYearlySubId = Platform.OS === "android" ? "com.lyritunes.premiumsubyearly" : "com.lyritunes.premiumsubyearly";
    if (Platform.OS === "android" && this.state.appConfig.secAndroidPurchase) {
      const purchases = await navigationService.props.RNIap.getPurchaseHistory();
      const subs = await navigationService.props.RNIap.getAvailablePurchases();
      const hasPurchased = purchases ? purchases.some((purchase: any) => purchase.productId === prodId) : false;
      const hasPurchasedSub = subs ? subs.some((purchase: any) => purchase.productId === prodSubId || purchase.productId === predYearlySubId) : false;
      isPremium = hasPurchased;
      isPremiumSubscriber = hasPurchasedSub;
      /*  packageName = await DeviceInfo.getBundleId();
       if (packageName !== "com.can.lyritunes" && this.state.appConfig.checkJacked) {
         isPremium = isPremiumSubscriber = false;
       } */
      //isPremiumSubscriber = false;
      console.log("purchases", hasPurchased)
      //console.log("packageName", packageName)

    } else {
      await navigationService.props.adapty.activate('public_live_XrSQI2uC.jvv4HficOrt8Y37NPsfB');
      try {
        const info = await navigationService.props.adapty.getProfile();
        if (info?.nonSubscriptions?.[prodId] && !info?.nonSubscriptions?.[prodId]?.isRefund) {
          isPremium = true;
          console.log("premium")
        }
        console.log("subscription info", JSON.stringify(info))
        if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund) || (info?.subscriptions?.[predYearlySubId]?.isActive && !info?.subscriptions?.[predYearlySubId]?.isRefund)) {
          isPremiumSubscriber = true;
          isPremium = true;
          console.log("premium")
        }
      } catch (error: any) {
        console.log("error", error);
        //toast.show(error.localizedDescription, { type: 'error' })
        this.showToast(error.localizedDescription, "error")
      }
    }
    /* isPremium = false;
    isPremiumSubscriber = false; */

    if (this.state.isPromoter || this.state.isLikePremium || ((new Date).getTime() < (this.state.user?.premiumUntil || 0))) {
      console.log("isPromoter")
      isPremium = true;
      isPremiumSubscriber = true;
    }
    this.setState({ checkedStore: true, isPremium: isPremiumSubscriber || isPremium, isPremiumSubscriber })

    if (!isPremium) {
      if (!navigationService.props.deniedTrackingTransparency) {
        const {
          consentStatus,
          isConsentFormAvailable,
          isRequestLocationInEeaOrUnknown,
        } = await navigationService.props.UMP.requestConsentInfoUpdate()
        if (
          isRequestLocationInEeaOrUnknown &&
          isConsentFormAvailable &&
          consentStatus === (navigationService.props.UMP.CONSENT_STATUS.REQUIRED)
        ) {
          const { consentStatus } = await navigationService.props.UMP.showConsentForm()
          AsyncStorage.setItem("consentStatus", consentStatus.toString());

        }
        const consentStatus2 = await AsyncStorage.getItem("consentStatus");
        console.log("consentStatus2", consentStatus2)
        if (consentStatus2 !== "3" && consentStatus2) {
          this.setState({ requestNonPersonalizedAdsOnly: true })
        }
      } else {
        this.setState({ requestNonPersonalizedAdsOnly: true })
      }

      let generatedLyricsNumber = 0;
      generatedLyricsNumber = parseInt(await AsyncStorage.getItem("generatedLyricsNumber") || "0") || 0;
      if (generatedLyricsNumber >= 2) {
        this.playIntroAd();
        AppState.addEventListener("change", async (nextAppState: any) => {
          if (nextAppState === "active") {
            this.getUnseenNotifications();
            if (this.state.adData.hasOpenAd) {
              this.playIntroAd();
            }
            if (this.state.user && this.state.user?.isOnCampaign) {
              const acc = (await API.graphql({ query: queries.getUser, variables: { id: this.state.user.id } }) as any).data.getUser;
              if (((new Date).getTime() < (acc.premiumUntil || 0))) {
                this.setState({ user: acc, isPremium: true, isPremiumSubscriber: true });
              } else {
                this.setState({ user: acc });
              }
            }
          }

        });
      }

    }

    /*  AppState.addEventListener("change", async (nextAppState: any) => {
       if (nextAppState === "active" && this.state.user?.id) {
         //refresh cognito credentials
         Auth.currentAuthenticatedUser().then((user) => {
           console.log("user", user)
         }).catch((err) => {
           console.log("err", err)
         });
         const session = await Auth.currentSession();
 
       }
     }); */


  }

  playIntroAd = async () => {
    if (this.state.isPremium || this.doNotPlayAd) return;
    this.doNotPlayAd = true;
    if (this.openAdTm) clearTimeout(this.openAdTm);

    this.createAd('op', null, () => {
      this.openAdTm = setTimeout(() => {
        this.doNotPlayAd = false;
      }, 5 * 1000)
    })

  }

  setPlayAd(doNot?: boolean) {
    if (this.openAdTm) clearTimeout(this.openAdTm);
    if (doNot) {
      this.doNotPlayAd = true;
    } else {
      this.openAdTm = setTimeout(() => {
        this.doNotPlayAd = false;
      }, 5000);
    }

  }

  buyPremium = async () => {
    console.log("buyPremium")
    try {


      this.setPlayAd(true);
      const packName = Platform.OS === "android" ? "com.lyritunes.premium" : "com.lyritunes.premium2";
      if (Platform.OS === "android" && this.state.appConfig.secAndroidPurchase) {
        console.log("yaya");
        const products = await navigationService.props.RNIap.getProducts({ skus: [packName] });
        //console.log("products", products)
        //navigationService.props.RNIap.initiatePurchase(packName);
        await navigationService.props.RNIap.requestPurchase({ skus: [packName] });
      } else {
        const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
        const products = await navigationService.props.adapty.getPaywallProducts(paywall);
        //const { products } = await adapty.getPaywallProducts();
        console.log("products", products)
        const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
        const {
          receipt,
          purchaserInfo,
          product
        }: any = await navigationService.props.adapty.makePurchase(adaptyProduct);

        const info = await navigationService.props.adapty.getProfile();
        if (info?.nonSubscriptions?.[packName] && !info?.nonSubscriptions?.[packName]?.isRefund) {
          console.log("receipt", receipt);
          console.log("purchaserInfo", JSON.stringify(purchaserInfo));
          console.log("product", JSON.stringify(product));
          this.showToast("Success, you are now a LyriTunes Premium user!", "success");
          this.setState({ isPremium: true })
          this.logEvent("buy_premium");
          this.setPlayAd(false);
          this.sendBuyMail(false);
          console.log("premium")
        }


      }
      //toast.show("Success, you are now a YouVi Premium user!", { type: 'success' })

      /*  if (Platform.OS === "android" && this.state.packageName !== "com.can.lyritunes" && this.state.appConfig.checkJacked) {
         return;
       } */


    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")

      //this.showToast(error.localizedDescription, "error");
    }

  };
  buyPremiumSubscription = async (isYearly?: boolean) => {
    console.log("buyPremium")
    try {


      this.setPlayAd(true);
      let packName = "";
      if (!isYearly) {
        packName = Platform.OS === "android" ? "com.lyritunes.premiumsub" : "com.lyritunes.premiumsub4";

      } else {
        packName = Platform.OS === "android" ? "com.lyritunes.premiumsubyearly" : "com.lyritunes.premiumsubyearly";
      }

      if (Platform.OS === "android" && this.state.appConfig.secAndroidPurchase) {
        console.log("yaya");
        const products = await navigationService.props.RNIap.getSubscriptions({ skus: [packName] });
        console.log("products", JSON.stringify(products))
        await navigationService.props.RNIap.requestSubscription({
          sku: packName,
          subscriptionOffers: [{ sku: packName, offerToken: products[0].subscriptionOfferDetails[0].offerToken }],

        });
      } else {
        const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
        const products = await navigationService.props.adapty.getPaywallProducts(paywall);
        console.log("products", products)
        const adaptyProduct = products.find((p: any) => p.vendorProductId === packName) || products[0];
        const {
          receipt,
          purchaserInfo,
          product
        }: any = await navigationService.props.adapty.makePurchase(adaptyProduct);
        console.log("receipt", receipt);
        console.log("purchaserInfo", JSON.stringify(purchaserInfo));
        console.log("product", JSON.stringify(product));
        const info = await navigationService.props.adapty.getProfile();

        console.log("info", JSON.stringify(info))
        if (info?.subscriptions?.[packName]?.isActive && !info?.subscriptions?.[packName]?.isRefund) {
          this.showToast("Success, you are now a LyriTunes Premium subscriber!", "success");
          this.setState({ isPremiumSubscriber: true, isPremium: true })
          this.logEvent(`buy_${isYearly ? 'yearly' : 'monthly'}_premium_subscription`);
          this.setPlayAd(false);
          this.sendBuyMail(true, isYearly);
        }

      }

      /*  if (Platform.OS === "android" && this.state.packageName !== "com.can.lyritunes" && this.state.appConfig.checkJacked) {
         return;
       } */


    } catch (error: any) {
      console.log("error", error);
      //toast.show(error.localizedDescription, { type: 'error' })
      this.showToast(error.localizedDescription || error.message, "error")

      //this.showToast(error.localizedDescription, "error");
    }

  };
  sendBuyMail = async (isSub: boolean, isYearly?: boolean) => {
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: 'cristian.anderes.radu@gmail.com',
          message: `Sucessfully bought premium`,
          subject: `LyriTunes Buy ${isSub ? (isYearly ? 'Yearly Sub' : 'Monthly Sub') : 'Premium'} - ${Platform.OS.toString()}!`
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  sendMail = async (emailTo: string, subject: string, message: string) => {
    try {
      await API.post('lyritunesrest', `/rest/send-email/`, {
        body: {
          emailFrom: 'no-reply@lyritunes.com',
          emailTo: emailTo,
          message: message,
          subject: subject
        }
      })
    } catch (e: any) {
      console.log("error", e)
    }
  }
  restorePurchase = async () => {
    console.log("restore")
    const prodId = Platform.OS === "android" ? "com.lyritunes.premium" : "com.lyritunes.premium2";
    const prodSubId = Platform.OS === "android" ? "com.lyritunes.premiumsub" : "com.lyritunes.premiumsub4";
    const predYearlySubId = Platform.OS === "android" ? "com.lyritunes.premiumsubyearly" : "com.lyritunes.premiumsubyearly";
    if (Platform.OS === "android" && this.state.appConfig.secAndroidPurchase) {
      try {
        const purchases = await navigationService.props.RNIap.getPurchaseHistory();
        const subs = await navigationService.props.RNIap.getAvailablePurchases();
        const hasPurchased = purchases ? purchases.some((purchase: any) => purchase.productId === prodId) : false;
        const hasPurchasedSub = subs ? (subs.some((purchase: any) => purchase.productId === prodSubId) || subs.some((purchase: any) => purchase.productId === predYearlySubId)) : false;
        if (hasPurchasedSub) {
          this.setState({ isPremium: true, isPremiumSubscriber: true })
          this.showToast("Success, you are now a LyriTunes Premium subscriber!", "success")
          return
        }
        if (hasPurchased) {
          this.showToast("Success, you are now a LyriTunes Premium user!", "success")
          this.setState({ isPremium: true })
        }

      } catch (error: any) {
        console.log("error", error);
        this.showToast(error.localizedDescription || error.message, "error")
      }
    } else {
      try {

        const paywall = await navigationService.props.adapty.getPaywall('PlacementID');
        console.log("paywall", paywall)

        const products = await navigationService.props.adapty.getPaywallProducts(paywall);
        console.log("products", products)
        await navigationService.props.adapty.restorePurchases()
        const info = await navigationService.props.adapty.getProfile();
        console.log("info", JSON.stringify(info))
        if ((info?.subscriptions?.[prodSubId]?.isActive && !info?.subscriptions?.[prodSubId]?.isRefund) || (info?.subscriptions?.[predYearlySubId]?.isActive && !info?.subscriptions?.[predYearlySubId]?.isRefund)) {
          this.setState({ isPremium: true, isPremiumSubscriber: true })
          this.showToast("Success, you are now a LyriTunes Premium subscriber!", "success")
          console.log("premium sub")
          return;

        }

        if (info?.nonSubscriptions?.[prodId] && !info?.nonSubscriptions?.[prodId]?.isRefund) {
          this.showToast("Success, you are now a LyriTunes Premium user!", "success")
          this.setState({ isPremium: true })
        }


        /* let isPremium = false;
        console.log("receipt", receipt);
        console.log("purchaserInfo", JSON.stringify(purchaserInfo));
        if (purchaserInfo?.nonSubscriptions?.[prodId]) {
          isPremium = true;
          console.log("premium")
        }
        if (purchaserInfo?.subscriptions?.[prodSubId]?.isActive) {
          this.setState({ isPremium: true, isPremiumSubscriber: true })
          this.showToast("Success, you are now a LyriTunes Premium subscriber!", "success")
          console.log("premium sub")
          return;
        }
        if (isPremium) {
          //toast.show("Success, you are now a YouVi Premium user!", { type: 'success' })
          this.showToast("Success, you are now a LyriTunes Premium user!", "success")
          this.setState({ isPremium: true })
        } */

        //console.log("product", JSON.stringify(product));

      } catch (error: any) {
        console.log("error", error);
        this.showToast(error.localizedDescription, "error")
      }
    }
  };
  showUpdateAlert = (appConfig: any) => {
    Alert.alert(
      'Update Needed',
      `Hey, there's a new version in town, please update for the best performance!`,
      [

        {
          text: 'Update',
          onPress: () => {
            Linking.openURL(Platform.OS === 'android' ? appConfig.androidApp : appConfig.iOSApp);
            setTimeout(() => {
              this.showUpdateAlert(appConfig);
            }, 1000);

          }
        },
      ],
      { cancelable: false })
  }
  setAppProp = (obj: any, callback?: any) => {
    let appConfig = { ...this.state };
    appConfig = { ...appConfig, ...obj };
    this.setState(appConfig, () => {
      if (callback) {
        callback();
      }
    });
  }

  setScreen = (screen: string, navigate?: boolean, params?: any, callback?: any) => {
    if (!this.firstScreen) {
      this.firstScreen = screen;
    } else {
      if (!navigationService.props.setHome) {
        navigationService.resetRoutes();
        navigationService.props.setHome = true;
      }
    }
    this.playSound("", "");
    this.setState({
      screen, routeParams: params
    }, () => {
      if (navigate) {
        navigationService.navigate(screen, params)
        this.logEvent(`navigate_${screen}`);
      }
      if (callback) {
        callback();
      }

    })
  };
  playTrack = async (track: string, volume?: number, prop?: string) => {
    return new Promise(async (resolve, reject) => {
      track = track.indexOf("soundtracks/") !== -1 ? track : track.replace('wav', 'mp3');
      let snd
      if (track.indexOf("soundtracks/") !== -1) {
        snd = await Storage.get(track.replace('public/', ''), { level: 'public' })
        console.log("snd", snd)
      }
      const sound = track.indexOf("https") === -1 ? new this.props.Sound(snd || track, !snd ? this.props.Sound.MAIN_BUNDLE : "", (error: any) => {

        if (error) {
          console.log('failed to load the sound', error);
          return;
        }
        if (volume) sound.setVolume(volume);

        //if (this.state.isPlaying)
        //setTimeout(() => {
        if (this.canPlay) {
          sound.play((success: any) => {

          });
        } else {
          sound.stop();
          sound.release();
          //sound = null;
        }
        // }, 1)
        resolve(sound)
      }) : new this.props.Sound(track, "", (error: any) => {
        if (error) {
          console.log('failed to load the sound', error);
          return;
        }

        this.setState({ audioDuration: sound.getDuration(), isPlaying: true })

        //if (this.state.isPlaying)
        // setTimeout(() => {
        if (this.canPlay) {
          sound.play((success: any) => {

          });
        } else {
          sound.stop();
          sound.release();
          // sound = null;
        }
        resolve(sound)
        //}, 1)

      });
      this[prop] = sound;
    })

  }
  setIntrv = (callback: any, time: number) => {
    if (this.tm) {
      clearTimeout(this.tm);
      this.tm = null;
    }
    if (!callback) return;
    this.tm = setTimeout(() => {
      callback();
      this.setIntrv(callback, time);
    }, time);
  }
  playSound = async (track: string, lyrics: string) => {
    if (this.tm) {
      clearInterval(this.tm);
      this.setState({ currentPlayTime: 0 })

    }
    this.canPlay = !(track === "" && lyrics === "");
    //if (!this.canPlay) {
    try {
      this.audio.stop();
      this.audio.release();
      this.audio = null;
    } catch (e) {
    }
    try {
      //console.log("stop", this.soundtrack)
      this.soundtrack.stop();
      this.soundtrack.release();
      this.soundtrack = null;
    } catch (e) {
    }
    // }

    //this.setIntrv(null, 1000);
    try {
      this.props.Sound.setCategory('Playback');
    } catch (e: any) {

    }

    if (!track && !lyrics) {
      this.setState({ isPlaying: false })
      return;
    }

    let audio, soundtrack;
    let promise = []
    if (track) {
      promise.push(this.playTrack(track, Platform.OS === 'web' ? 0.17 : 0.2, "soundtrack"))

    }
    if (lyrics) {
      promise.push(this.playTrack(lyrics, 1, "audio"))
    }
    [soundtrack, audio] = await Promise.all(promise);
    /*let soundtrack: any
    if (track) soundtrack = await this.playTrack(track, 0.2);*/
    /*let audio: any;
    if (lyrics) audio = await this.playTrack(lyrics);*/
    let playedSoundsNumber = 0;
    if (Platform.OS !== "web") {
      playedSoundsNumber = parseInt(await AsyncStorage.getItem("playedSoundsNumber") || "0") || 0;
      playedSoundsNumber++;
      AsyncStorage.setItem("playedSoundsNumber", playedSoundsNumber.toString());
    }

    this.setState({ audio, soundtrack, playedSoundsNumber, isPlaying: true })

    this.logEvent(`play_sound`);
  }

  setURLOpener() {
    if (Platform.OS === 'web') {
      return;
    }
    const opener = (url: string) => {
      //Browser.open({ url })
      InAppBrowser.open(url)
    };
    const config: any = Auth.configure(null);
    config.oauth.urlOpener = opener;
    Auth.configure(config);
  }

  signOut = async () => {
    this.setUser(null);
    this.forceUpdate();
    this.setURLOpener();
    await Auth.signOut({ global: true });
    InAppBrowser.close();


    //this.setScreen('Home', true);

  }
  updateAccount = async (date: any) => {
    //this.registerEvent("update account");
    const account = { id: this.state.user.id, ...date };
    const acc = (await API.graphql(graphqlOperation(updateUser, { input: account })) as any).data.updateUser;
    this.setState({ user: acc })
  }
  deleteUser = async () => {
    await API.graphql(graphqlOperation(deleteUser, { input: { id: this.state.user?.id } }))
    this.showToast("Your account has been deleted", "success");
    this.signOut();
    this.setScreen("Home");

  }
  logEvent = (eventName: string) => {
    const eve = `${Platform.OS.toString().toLowerCase()}_${eventName}`;
    try {
      if (Platform.OS === "web") {
        ReactGA.event({ category: "", action: eve });
      } else {
        analytics().logEvent(eve, { platform: Platform.OS.toString().toLowerCase() });
      }
    } catch (e) {
      console.log("log error", JSON.stringify(e))
    }
  }
  setUser = async (user: any, callback?: any) => {
    if (this.state.screen === "Sign In") this.setScreen('Home', true)
    //console.log("user", user)

    if (user) {


      let familyName, givenName: string;
      if (user.username.toLowerCase().indexOf('apple') !== -1) {
        familyName = user.attributes.given_name.split(" ")[1];
        givenName = user.attributes.given_name.split(" ")[0];
      } else {
        familyName = user.attributes.family_name;
        givenName = user.attributes.given_name;
      }

      let account: any = {
        id: user.attributes.sub,
        userLikeType: "public",
        userPostsType: "public",
        userSharesType: "public",
        firstName: givenName,
        lastName: familyName,
        picture: user.username.toLowerCase().indexOf('facebook') !== -1 ? (user.attributes['picture'] ? JSON.parse(user.attributes['picture']).data.url : '') : user.attributes.picture,
        email: user.attributes.email
      };
      const token = Platform.OS !== "web" ? await messaging().getToken() : "";
      console.log("device token", token)
      if (token) {
        account.deviceToken = token;
      }

      /*if (Platform.OS !== 'web' && device) {
        account.devices = [device];
      }*/
      /*if (user.username.indexOf('facebook') !== -1) {
        user.attributes['picture'] = JSON.parse(user.attributes['picture']).data.url;
      }*/
      let acc: any;
      config.aws_appsync_authenticationType = "AWS_IAM";
      Amplify.configure(config);
      let isAdmin = false;
      if (account.email?.toLowerCase() === 'yonodo10@gmail.com' || account.email?.toLowerCase() === 'bara.florentina@gmail.com') {
        isAdmin = true;
      }
      acc = (await API.graphql({ query: queries.getUser, variables: { id: account.id } }) as any).data.getUser;
      if (!acc) {
        acc = (await API.graphql(graphqlOperation(createUser, { input: account })) as any).data.createUser;
      } else {
        acc = (await API.graphql(graphqlOperation(updateUser, {
          input: {
            id: acc.id,
            deviceToken: token,
            userLikeType: "public",
            userPostsType: "public",
            userSharesType: "public",
            picture: account.picture
          }
        })) as any).data.updateUser;
        if (Platform.OS !== 'web') {

          setTimeout(async () => {
            console.log("isPremium user----", this.state.isPremium)

            if ((this.state.isPremium && !acc.isPremium) || (this.state.isPremiumSubscriber && !acc.isPremiumSubscription)) {
              let usInput: any = {
                id: acc.id,
                isPremiumSubscription: this.state.isPremiumSubscriber,
                isPremium: this.state.isPremium
              }
              await API.graphql(graphqlOperation(updateUser, {
                input: usInput
              }))
            }

          }, 4000)

        }
      }
      console.log("acc", acc)

      this.setState({ user: acc, isAdmin }, async () => {
        this.getUnseenNotifications();
        if (navigationService.props.initialNotification?.data) {
          //if (Platform.OS === "android") {
          this.gotoNotification(navigationService.props.initialNotification.data);
          navigationService.props.initialNotification = null;
          /* } else {
            navigationService.props.initialNotification = null;
          } */
        }

        const subscription = API.graphql(graphqlOperation(onCreateLyriNotification, { filter: { userId: { eq: this.state.user?.id } } })).subscribe({
          next: (notificationData: any) => {
            //console.log("notificationData", notificationData)
            setTimeout(() => {
              this.getUnseenNotifications();
            }, 3000)
          }
        });
      });

      try {
        InAppBrowser.close();
      } catch (e) {

      }

      if (Platform.OS !== 'web') {
        if (user.username.toLowerCase().indexOf('facebook') || user.username.toLowerCase().indexOf('apple') || user.username.toLowerCase().indexOf('google')) {
          const promoter = (await API.graphql({ query: queries.getPromoters, variables: { id: user.attributes.email } }) as any).data.getPromoters;
          console.log("promoter", promoter)
          if (promoter) this.setState({ isPromoter: true, isPremium: true, isPremiumSubscriber: true })
        }
        console.log("acc", acc, this.state.appConfig.numLikesPremium);
        if (!this.state.isPremium && acc.userLikes >= (this.state.appConfig.numLikesPremium || 100)) {
          const hasShownLikePremium = await AsyncStorage.getItem("hasShownLikePremium");
          this.setState({ isLikePremium: true, isPremium: true, isPremiumSubscriber: true })
          if (!hasShownLikePremium) {
            Alert.alert("Premium", `Congratulations, you've reached ${this.state.appConfig.numLikesPremium || 100} total likes and you now have a free lifetime subscription  🎉`, [{ text: "OK", onPress: () => AsyncStorage.setItem("hasShownLikePremium", "true") }])
          }
        }


      }
      if (((new Date).getTime() < (acc.premiumUntil || 0))) {
        this.setState({ isPremium: true, isPremiumSubscriber: true });
      }
    } else {
      console.log("non on user")
      config.aws_appsync_authenticationType = "API_KEY";
      Amplify.configure(config);
      this.setState({
        user: null, isAdmin: false
      }, callback)


    }

  }
  appReview = async () => {
    if (InAppReview.isAvailable()) {
      InAppReview.RequestInAppReview()
        .then((hasFlowFinishedSuccessfully) => {
          // when return true in android it means user finished or close review flow
          console.log('InAppReview in android', hasFlowFinishedSuccessfully);

          // when return true in ios it means review flow lanuched to user.
          console.log(
            'InAppReview in ios has launched successfully',
            hasFlowFinishedSuccessfully,
          );

          // 3- another option:
          if (hasFlowFinishedSuccessfully) {
            // do something for ios
            // do something for android
          }

        })
        .catch((error) => {

          console.log("InAppReview error", error);
        });
    } else {
      console.log("InAppReview not available")
    }
  }
  error = (e: any) => {
    this.setState({ isBusy: false })
    this.showToast(e.message, 'error')
  }
  hideViewPost = () => {

    //set the pointerEvents on navigationService.props.viewPostRef to none and visibility to hidden
    navigationService.props.viewPostRef.setNativeProps({
      pointerEvents: 'none',
      opacity: 0
    });
    setTimeout(() => {
      this.setState({ viewPost: null })
    }, 100)
    /* navigationService.props.viewPostRef.fadeOut(500).then(() => {
      //make the navigationService.props.viewPostRef click trough
      this.setState({ viewPost: null })
    }); */
  }
  getEmojy = (name: string) => {
    switch (name) {
      case 'Like':
        return '👍';
      case 'Love':
        return '❤️';
      case 'Haha':
        return '😂';
      case 'Wow':
        return '😮';
      case 'Sad':
        return '😢';
      case 'Angry':
        return '😡';
      case 'Happy':
        return '😀';
      case 'Cry':
        return '😭';
      case 'Laugh':
        return '😆';
      case 'Smile':
        return '🙂';
      case 'Wink':
        return '😉';
      case 'Sarcastic':
        return '😏';
      case 'Confused':
        return '😕';
      case 'Scared':
        return '😨';
      case 'Surprised':
        return '😱';
      case 'Sick':
        return '🤢';
      case 'Tired':
        return '😴';
      case 'Hungry':
        return '🤤';
      case 'Thirsty':
        return '😵';
      case 'Bored':
        return '😑';
      default:
        return '';
    }
  }
  hideMenu = () => {
    const slideOutLeft = ({
      from: {
        transform: [{ translateX: 0 }], // Starts at its natural position
      },
      to: {
        transform: [{ translateX: -280 }], // Ends offscreen 100 px to the left
      },
    });
    //this.viewRef.fadeOut(1000).then(endState => setIsVisible(false));
    this.setState({ appMenuOpenedBack: false })
    navigationService.props.menuRef.animate(slideOutLeft, 200).then(() => {
      //make the navigationService.props.viewPostRef click trough
      this.setState({ appMenuOpened: false, appMenuOpenedBack: false })
    });

  }
  getCurrentTime = () => {
    console.log("getCurrentTime", this.currentPlayTime)
    return this.currentPlayTime;
  }
  showToast = (message: string, type: 'error' | 'success') => {
    let tp = type.toString();
    if (Platform.OS === 'web') {
      if (tp === 'error') {
        tp = 'danger';
      }
      const options: any = {
        placement: 'top',
        type: tp,
        duration: 3000,
        animationType: 'slide-in',
        animationDuration: 300,
        successColor: colors.primaryColor,
        offsetBottom: 70,
        offsetTop: 70,
        dangerColor: "#ad2b1a",
        warningColor: "orange",
        normalColor: "gray",
        textStyle: { fontSize: 15, color: "#ffffff" },
        swipeEnabled: true
      }
      toast.show(message, options);
    } else {
      this.props.Toast.show({
        type: tp,
        position: 'top',
        topOffset: 70,
        visibilityTime: 3000,
        text1: message,

      });
    }
    //toast.show("Show custom toast", { data: { title: 'Toast title' } })
  }

  render() {
    return (
      <AppContext.Provider value={
        {
          state: this.state,
          showToast: this.showToast,
          gotoNotification: this.gotoNotification,
          waitSomeSeconds: this.waitSomeSeconds,
          getMagicText: this.getMagicText,
          hideViewPost: this.hideViewPost,
          getUnseenNotifications: this.getUnseenNotifications,
          postComment: this.postComment,
          sendMail: this.sendMail,
          hideMenu: this.hideMenu,
          getEmojy: this.getEmojy,
          shareMe: this.shareMe,
          getListItems: this.getListItems,
          sendPushNotification: this.sendPushNotification,
          reportComment: this.reportComment,
          appReview: this.appReview,
          tweetPost: this.tweetPost,
          openReportUser: this.openReportUser,
          buyPremium: this.buyPremium,
          buyPremiumSubscription: this.buyPremiumSubscription,
          restorePurchase: this.restorePurchase,
          playRewardedAdAndGenerate: this.playRewardedAdAndGenerate,
          showRewarded: this.showRewarded,
          playRewardedAndGo: this.playRewardedAndGo,
          createAd: this.createAd,
          logEvent: this.logEvent,
          deleteUser: this.deleteUser,
          updateAccount: this.updateAccount,
          reportUserAccount: this.reportUserAccount,
          banUser: this.banUser,
          reportPost: this.reportPost,
          deletePost: this.deletePost,
          gotoStore: this.gotoStore,
          playSound: this.playSound,
          getCurrentTime: this.getCurrentTime,
          likeLyriPost: this.likeLyriPost,
          answerRiddle: this.answerRiddle,
          generateVideo: this.generateVideo,
          startViewCampaign: this.startViewCampaign,
          setUser: this.setUser,
          signOut: this.signOut,
          setScreen: this.setScreen,
          setAppProp: this.setAppProp,

        }}>
        {this.props.children}
      </AppContext.Provider>)
  }
}
