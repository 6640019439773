import { AntDesign, Entypo, FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import * as React from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    Text,
    View,
    FlatList,
    Platform,
    RefreshControl,
    AsyncStorage, Image, Modal, BackHandler, ScrollView, TextInput, ActivityIndicator
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import navigationService from './../services/navigation-service';
import { AppContext, IContext } from '../store/app-provider';
import { SheetManager } from "react-native-actions-sheet";
import ItemsList from "../components/items-list";
import { API } from "aws-amplify";
import * as queries from "./../graphql/queries";
import * as myqueries from "./../graphql/my-queries";

import LyriItem from "../components/lyri-item";

interface Props {

}

interface State {
    type: string

}

class WeekTopsScreen extends React.Component<Props, State> {
    static contextType = AppContext;
    declare context: IContext
    itemList: any

    constructor(props: any) {
        super(props);
        this.state = { type: 'public' }
    }

    async componentDidMount() {
        navigationService.props.setHome = true;
    }

    componentDidUpdate(prevPops: any) {

    }

    async getHistory(token?: string) {
        let topOfTheWeek = null;
        topOfTheWeek = (await API.graphql({
            query: myqueries.getTopPostsByTime,
            variables: { type: 'weekly', limit: 20, sortDirection: 'DESC' },
        }) as any).data.getTopPostsByTime
        const posts = topOfTheWeek.items.filter(item => item.lyriPost).map((item: any) => {
            item.lyriPost.isTopOfTheWeek = true;
            return item.lyriPost
        });

        return { items: posts, nextToken: topOfTheWeek.nextToken };
    }



    render() {
        const busyIndicator = () => {
            if (this.context.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }
        const renderItem = ({ item, index }: any) => {
            return <LyriItem index={index} onPress={() => {
            }} item={item} />

        }
        return (
            <View style={style.page}>
                <View style={[style.container]}>


                    <ItemsList ref={ref => {
                        this.itemList = ref
                    }} cache={false} disableBottomScroll={true} disableRefresh={false} renderItem={renderItem} stateKey={'weekTopsList'} fn={this.getHistory.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.weekTopsList}></ItemsList>



                    {busyIndicator()}
                </View>

            </View>
        )

    }
}

export default WeekTopsScreen


