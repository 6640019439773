import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, Modal } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';

import { FontAwesome5 } from "@expo/vector-icons";
import CommentBox from "./comment-box";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { LyriPost } from '../models';

interface State {

}

interface Props {
  post: LyriPost,
  style?: ViewStyle
}

class CommentsModal extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  componentDidMount() {

  }

  render() {

    return (

      <ActionSheet keyboardShouldPersistTaps={'handled'} onClose={() => {
        this.context.setAppProp({ viewPostComments: null })
      }} containerStyle={{
        zIndex: 9999,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: colors.borderRadius,
        maxWidth: colors.maxWidth
      }} elevation={3333} defaultOverlayOpacity={0.5} id={`comments-box`}>

        <View style={[style.fullWidth, style.column, {
          height: 500,
          justifyContent: 'flex-end',
          alignItems: 'center',
          backgroundColor: 'rgba(0,0,0,0)',
          padding: 0,
        }]}>
          <View style={[{
            height: '100%',
            width: '100%',
            maxWidth: colors.maxWidth,
            padding: 10,
            borderRadius: 10,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            paddingTop: 0,
            backgroundColor: "#fff"
          }]}>
            <CommentBox style={{ width: '100%', flex: 1 }} post={this.context.state.viewPostComments} />
          </View>

        </View>
      </ActionSheet>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default CommentsModal;
