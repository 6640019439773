import { Image } from 'react-native';
import navigationService from "../services/navigation-service";

class SoundWeb {
  audio: any
  track: any
  duration: any
  callback: any
  songs: string[] = ["sound1", "sound2", "sound3", "sound4", "sound5", "sound6", "country1", "beach1", "rock1"]
  constructor(track: string, loc: string, callback: any) {
    if (!track) return;
    this.track = track;
    this.callback = callback;
    this.startMe();

    //callback();
  }

  startMe = async () => {
    if (this.track.indexOf("https") === -1) {
      //extract the number from the track name
      const trackNumber = this.songs.indexOf(this.track.replace(".mp3", ""));
      const asset = navigationService.props.assets[trackNumber];
      console.log("this.track", this.track);
      console.log("asset", asset);
      //await asset.downloadAsync();
      this.track = asset.uri;
    }
    //console.log("SoundWeb", track)
    this.audio = new Audio(this.track);

    //this.audio.muted = true;
    //this.audio.crossOrigin = "anonymous";
    //this.audio.play();
    //console.log("SoundWeb", this.audio)
    setTimeout(() => {
      this.callback();
    }, 1000);
  }

  getDuration() {
    return this.audio.duration;
  }

  setCategory = (category: string) => {

  }
  play = () => {
    this.audio.play();
    console.log("play");
  }
  setVolume = (volume: number) => {
    this.audio.volume = volume;
  }
  stop = () => {
    if (this.audio) this.audio.pause();
  }
  release = () => {

  }
}

export default SoundWeb
