import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { Ionicons } from "@expo/vector-icons";
import LyriItem from "../components/lyri-item";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as myqueries from "../graphql/my-queries";

interface Props {

}

interface State {

}

class MyLikedScreen extends React.Component<Props, State> {

  static contextType = AppContext;
  declare context: IContext

  constructor(props: any) {
    super(props);

  }

  async componentDidMount() {

  }

  async getMyPosts(token?: string) {
    let history = (await API.graphql({
      query: myqueries.getLikesByTimeAndUserCustom,
      variables: { userId: this.context.state.user?.id, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getLikesByTimeAndUser;
    //console.log(history);
    history.items = history.items.filter((item: any) => item.post);
    return { items: history.items, nextToken: history.nextToken };
  }

  componentDidUpdate(prevPops: any) {

  }

  render() {
    const renderItem = ({ item, index }: any) => {
      return <LyriItem index={index} isMyPost={true} onPress={() => {
        console.log("on press")
        this.context.setScreen('View', true, { id: item.post.id });

      }} item={item.post} />

    }
    const busyIndicator = () => {
      /* if (this.props.app.isBusy) {
          return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      } */
    }

    return (

      <View style={style.page}>
        <View style={[style.container]}>
          <ItemsList disableBottomScroll={true} disableRefresh={false} cache={false} renderItem={renderItem} stateKey={'myLikedList'} fn={this.getMyPosts.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.myLikedList}></ItemsList>
        </View>
      </View>
    )
  };

}

export default MyLikedScreen;

const styles = StyleSheet.create({
  input: {
    padding: 5,
    height: 35,
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: colors.textColor,
    color: colors.textColor
  },
  screenItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 10,
    width: '100%',
    height: '100%',
    backgroundColor: colors.textColor
  }
});
