
import * as React from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import Top from '../components/top';





interface Props {

}

interface State {

}
class ChartsScreen extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext
    constructor(props: any) {
        super(props);

    }

    async componentDidMount() {

    }


    componentDidUpdate(prevPops: any) {


    }



    render() {

        const busyIndicator = () => {
            /* if (this.props.app.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            } */
        }


        return (

            <View style={style.page}>
                <View style={[style.container, { justifyContent: 'flex-start', padding: 0 }]}>

                    <ScrollView style={[style.fullSize, { padding: 0 }]}>
                        <Top type={"likes"}></Top>
                        <Top type={"posts"}></Top>
                        <Top type={"shares"}></Top>
                        <Top type={"riddles"}></Top>
                    </ScrollView>

                </View>
            </View>

        )
    };

}

export default (withTranslation()(ChartsScreen));

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});