import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, TextStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
interface State {
    initial?: number
}
interface Props {
    backgroundColor?: string,
    activeColor?: string,
    textColor?: string,
    initial?: number,
    textStyle?: TextStyle,
    data?: string[],
    selectedBtn?: any,
    style?: ViewStyle
}
class DoubleButtonBar extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext


    constructor(props: any) {
        super(props);
        this.state = { initial: this.props.initial }

    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.initial !== this.props.initial) {
            this.setState({ initial: this.props.initial })
        }
    }


    render() {

        return (
            <View style={[this.props.style, style.row, style.vcenter, { borderRadius: 25, height: 50, padding: 5, backgroundColor: this.props.backgroundColor }]}>
                {this.props.data?.map((item: any, index: number) => {
                    return <TouchableOpacity onPress={() => {
                        this.props.selectedBtn(index);
                        this.setState({ initial: index })
                    }} style={{ width: '50%', height: '100%' }} key={index}>
                        <View style={[style.row, style.vcenter, style.hcenter, { width: '100%', borderRadius: 25, height: '100%', backgroundColor: this.state.initial === index ? this.props.activeColor : this.props.backgroundColor }]}>
                            <Text style={[this.props.textStyle, { color: this.state.initial === index ? '#ffffff' : this.props.textColor }]}>{item}</Text>
                        </View>
                    </TouchableOpacity>
                })
                }
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default DoubleButtonBar;
