import * as React from 'react';
import { ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { withTranslation } from 'react-i18next';
import { AppContext, IContext } from '../store/app-provider';
import ItemsList from "../components/items-list";
import { Ionicons } from "@expo/vector-icons";
import LyriItem from "../components/lyri-item";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";

interface Props {

}

interface State {
    isBusy?: boolean
    newsDate: string
    day: string
}

class NewsScreen extends React.Component<Props, State> {

    static contextType = AppContext;
    declare context: IContext

    constructor(props: any) {
        super(props);
        //day is just the name of today's day like Monday, Tuesday, etc.
        const day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][new Date().getDay()];

        this.state = {
            day,
            newsDate: new Date().toISOString().split("T")[0]
        }
    }

    async componentDidMount() {

    }

    async getMyPosts(token?: string) {

        let history = (await API.graphql({
            query: queries.getPostsBySocial,
            variables: { socialType: "insta", limit: 20, filter: {}, sortDirection: 'DESC', nextToken: token },
        }) as any).data.getPostsBySocial;
        //console.log(history);
        return { items: history.items, nextToken: history.nextToken };
    }

    componentDidUpdate(prevPops: any) {

    }

    render() {

        const renderItem = ({ item, index }: any) => {
            return <LyriItem isMyPost={false} index={index} onPress={() => {
            }} item={item} />

        }
        const busyIndicator = () => {
            if (this.context.state.isBusy || this.state.isBusy) {
                return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
            }
        }

        return (

            <View style={style.page}>
                <View style={[style.container]}>
                    {/* <Text style={{ color: colors.textColor, fontFamily: 'Jost', fontSize: 18, width: '100%', textAlign: 'left', paddingLeft: 20 }}>News for {this.state.day}</Text> */}
                    <ItemsList cache={false} disableBottomScroll={true} disableRefresh={false} renderItem={renderItem} stateKey={'newsList'} fn={this.getMyPosts.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.newsList}></ItemsList>
                    {busyIndicator()}
                </View>
            </View>
        )
    };

}

export default NewsScreen;

const styles = StyleSheet.create({
    input: {
        padding: 5,
        height: 35,
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: colors.textColor,
        color: colors.textColor
    },
    screenItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 10,
        width: '100%',
        height: '100%',
        backgroundColor: colors.textColor
    }
});
