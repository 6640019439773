import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  RefreshControl,
  FlatList,
  TextInput, ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  ScrollView
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';

import { AntDesign, Entypo, Ionicons } from "@expo/vector-icons";
import { Keyboard } from 'react-native';
import ItemsList from "./items-list";
import * as queries from "../graphql/queries";
import { API } from "aws-amplify";
import UserButton from "./user-button";
import { LyriComment, LyriPost } from '../models';

import DaysPassed from './days-passed';
import { SheetManager } from 'react-native-actions-sheet';
import LyriItem from './lyri-item';

interface State {
  isBusy?: boolean,
  replyToUser?: any,
  comment?: string
}

interface Props {
  post: LyriPost,
  style?: ViewStyle
}

class CommentBox extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  itemList: any

  constructor(props: any) {
    super(props);
    this.state = {}

  }

  async commentPost() {
    if (!this.state.comment) {
      return;
    }
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    this.setState({ isBusy: true });
    await this.context.postComment(this.props.post, this.state.comment || "", this.state.replyToUser)
    this.itemList.refresh();
    this.setState({ isBusy: false, comment: '', replyToUser: null });
    Keyboard.dismiss();
  }

  async reportComment(comment: LyriComment) {
    if (!this.context.state.user) {
      this.context.setScreen('Sign In', true);
      return;
    }
    SheetManager.hide('comments-box');
    await this.context.reportComment(comment);

  }

  componentDidMount() {

  }
  async getComments(token?: string) {
    let comments = (await API.graphql({
      query: queries.getCommentsByTime,
      variables: { postId: this.props.post.id, limit: 20, sortDirection: 'DESC', nextToken: token },
    }) as any).data.getCommentsByTime
    comments.items = comments.items.filter((item: any) => item.user);
    return { items: comments.items, nextToken: comments.nextToken };
  }
  openUserMenu(user: any) {
    this.context.setAppProp({
      showCommentBox: false,
    })
    //this.context.openReportUser(user);
  }

  render() {
    const busyIndicator = () => {
      if (this.state.isBusy) {
        return <ActivityIndicator size="small" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    const renderItem = ({ item, index }: any) => {
      return item.user ?
        <View style={[style.row, style.vcenter, {
          marginTop: 15, marginBottom: 10, padding: 10, backgroundColor: colors.pinkish,
          borderRadius: colors.borderRadius
        }]}>

          <UserButton hideBorder={false} user={item.user} onPress={this.openUserMenu.bind(this, item.user)} size={25}></UserButton>
          <View style={{
            flex: 1,
            marginLeft: 10
          }}>
            <View style={[style.column, { flex: 1 }]}>
              {item.replyToUser ? <Text style={[style.text, { fontSize: 16, color: colors.bluePrimary }]}>{`@${item.replyToUser?.firstName} ${item.replyToUser?.lastName.substr(0, 1)}`}</Text> : null}
              <Text style={[style.text, { fontSize: 16 }]}>{item.text}</Text>
            </View>

            <View style={[style.row, style.vcenter, { marginTop: 10 }]}>
              <TouchableOpacity onPress={this.reportComment.bind(this, item)}>
                <AntDesign style={{

                }} name="flag" size={16} color={colors.iconColor} />
              </TouchableOpacity>
              {item.user?.id !== this.context.state.user?.id ? <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => {
                this.setState({ replyToUser: item.user })
              }}>
                <Entypo style={{
                }} name="reply" size={16} color={colors.iconColor} />
              </TouchableOpacity> : null}
            </View>
          </View>
          <DaysPassed style={{ position: 'absolute', right: 10, bottom: 10 }} date={item?.savedAt}></DaysPassed>
        </View> : null;

    }
    return (
      <View style={[this.props.style, style.vcenter]}>
        <LyriItem index={0} onPress={() => { }} item={{ ...this.props.post, isTopOfTheWeek: false }} isCompact={true} style={{ width: '100%', marginBottom: 10 }} ></LyriItem>
        <ItemsList style={{ marginBottom: 10 }} ref={ref => {
          this.itemList = ref
        }} cache={false} disableBottomScroll={true} renderItem={renderItem} disableRefresh={false} stateKey={'commentsList'} fn={this.getComments.bind(this)} nextTokenName={'nextToken'} stateItem={this.context.state.commentsList}></ItemsList>

        {this.state.replyToUser ? <View style={[style.row, style.vcenter, style.fullWidth, { marginBottom: 0 }]}>
          <Text style={[style.text, { fontSize: 16, color: colors.bluePrimary }]}>@{this.state.replyToUser?.firstName} {this.state.replyToUser?.lastName.substr(0, 1)}</Text>
          <TouchableOpacity onPress={() => {
            this.setState({ replyToUser: null })
          }}><View style={[style.row, style.vcenter, style.hcenter, { width: 40, height: 40, borderRadius: 20, backgroundColor: 'transparent' }]}>
              <AntDesign name="close" size={18} color={colors.greaysh} />
            </View></TouchableOpacity>
        </View> : null}
        <View style={{ height: 50 }}></View>
        <View style={[style.row, style.vcenter, style.fullWidth, { backgroundColor: "#ffffff", position: 'absolute', bottom: 5 }]}>

          <TextInput style={{
            height: 50,
            flex: 1,
            opacity: !this.context.state.user ? 0.5 : 1,
            borderRadius: colors.borderRadius,
            borderColor: colors.primaryColor,
            borderWidth: 0,
            backgroundColor: colors.pinkish,
            padding: 15
          }}
            placeholder="Add a comment"
            multiline={false}
            autoCorrect={false}
            blurOnSubmit={true}
            maxLength={500}
            enablesReturnKeyAutomatically={true}
            onSubmitEditing={() => {
              this.commentPost()

            }}
            contextMenuHidden={!this.context.state.user}
            value={this.state.comment}
            numberOfLines={2}
            onChangeText={(text) => this.setState({ comment: text })}
          />

          <View style={[style.fullHeight, style.row, style.hcenter, style.vcenter, { width: 40 }]}>


            {this.state.isBusy ?
              <ActivityIndicator size="small" style={{ marginLeft: 10 }} color={colors.busyIndicator} /> :
              <TouchableOpacity disabled={!this.context.state.user} onPress={this.commentPost.bind(this)} style={{ marginLeft: 10 }}>
                <Ionicons style={{ opacity: !this.context.state.user ? 0.5 : 1 }} name="ios-send-sharp" size={24} color={colors.bluePrimary} />
              </TouchableOpacity>}
          </View>
        </View>

      </View>

    )
  };

}

const styles = StyleSheet.create({
  mainTitle: {

    color: colors.secondaryColor,
    fontSize: 35,
    fontWeight: 'bold'
  },
  pageTitle: {
    width: '100%',
    textAlign: 'center',
    color: colors.secondaryColor,
    fontSize: 15,
    fontWeight: 'bold'
  },
});

export default CommentBox;
