import * as React from 'react';
import {
  Text,
  TouchableOpacity,
  StyleSheet,
  View,
  Image,
  ViewStyle,
  ActivityIndicator,
  Dimensions,
  FlatList,
  TextInput,
  ImageBackground,
  Linking,
  Platform
} from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';


import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
///@ts-ignore

///@ts-ignore
import { v4 as uuidv4 } from 'uuid';

import { Entypo, Feather, FontAwesome } from '@expo/vector-icons';
import navigationService from '../services/navigation-service';
import PrimaryButton from '../components/primary-button';
import { AppContext, IContext } from '../store/app-provider';
import { createPromoters, deletePromoters } from '../graphql/mutations';
import RadioGroup from '../components/radio-group';

interface State {
  isBusyCreate: boolean,
  tikEmail?: string,
  tikUrl?: string,
  selectedVideo: any,
  showControls: boolean,
  currentIndex: number,
  cheight: number,
  nextToken?: string,
  items: any[]
  isBusy: boolean
  viewType: 'list' | 'create'
}

interface Props {
  onPress?: (videoItem: any) => void;
  style?: ViewStyle
}

const screenWidth = Dimensions.get('window').width;

class FastClipsScreen extends React.Component<Props, State> {
  static contextType = AppContext;
  declare context: IContext
  tm: any;
  cheight: any
  flatListRef: any;

  constructor(props: any) {
    super(props);
    this.state = { isBusyCreate: false, viewType: 'list', cheight: 0, items: [], currentIndex: -1, isBusy: false, selectedVideo: null, showControls: false }

  }

  onViewableItemsChanged = ({ viewableItems }: any) => {

    if (this.tm) {
      clearTimeout(this.tm);
    }

    // this.tm = setTimeout(() => {
    if (viewableItems.length) {
      const index = viewableItems[0].index;
      this.playRewarded(index);
      const firstViewableItem = viewableItems[0].item;
      if (firstViewableItem) {
        //console.log("firstViewableItem", firstViewableItem)
        this.setState({ selectedVideo: firstViewableItem, showControls: false })
        this.tm = setTimeout(() => {
          this.setState({ currentIndex: index })
        }, 100)

      }
    }

  }

  nextVideo() {

  };

  playRewarded(index: number) {
    /* if (!this.context.state.isPremium && index && index % this.context.state.adData.maxFastClips === 0) {
      this.context.setAppProps({ showRewardModal: true })
    } */
  }

  async componentDidMount() {
    this.getListItems(true);
  }



  refresh() {
    this.setState({ nextToken: undefined, items: [] }, () => {
      this.getListItems(true);
    })
  }

  async getListItems(refresh?: boolean, addInitialItem?: any) {
    //return;
    if (!refresh && !this.state.nextToken) {
      return;
    }
    const oldItems = addInitialItem ? [addInitialItem] : (this.state.items || []);
    this.setState({ isBusy: true })
    //filter for tiktokId to not be null
    let clips = (await API.graphql({
      query: queries.getPromoterByType,
      variables: {
        type: 'fast',
        limit: 10,
        sortDirection: 'DESC',
        filter: {
          tiktokId: { ne: null }
        },
        nextToken: this.state.nextToken
      },
    }) as any).data.getPromoterByType;
    //for each clip getVideoInfo by tiktokId and uodate the videoUrl property of the clip
    const promises = clips.items.map(async (clip: any) => {
      const videoInfo = await this.getVideoInfo(clip.tiktokId);
      if (videoInfo?.aweme_detail) {
        clip.videoUrl = videoInfo.aweme_detail.video.play_addr.url_list[0];
      } else {
        clip.videoUrl = null;
      }
      return clip;
    })
    clips.items = await (await Promise.all(promises)).filter((clip: any) => clip.videoUrl);




    this.setState({ items: [...oldItems, ...clips.items], nextToken: clips.nextToken, isBusy: false })
  }

  displayFormatNumber(num: number) {
    if (num > 1000000) {
      return Math.floor(num / 1000000) + 'M'
    } else if (num > 1000) {
      return Math.floor(num / 1000) + 'K'
    } else {
      return num;
    }
  }

  getTime(timestamp: number) {
    //return time agi, like minutes ago, days ago, etc
    const seconds = Math.floor((new Date().getTime() - timestamp) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " years ago";
    } else if (interval === 1) {
      return interval + " year ago";
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + " months ago";
      } else if (interval === 1) {
        return interval + " month ago";
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
          return interval + " days ago";
        } else if (interval === 1) {
          return interval + " day ago";
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval > 1) {
            return interval + " hours ago";
          } else if (interval === 1) {
            return interval + " hour ago";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
              return interval + " minutes ago";
            } else if (interval === 1) {
              return interval + " minute ago";
            } else {
              return Math.floor(seconds) + " seconds ago";
            }
          }
        }
      }
    }

  }
  async getVideoInfo(videoId: string) {
    let videoInfo = (await API.get('lyritunesrest', '/rest/tiktokinfo', {
      queryStringParameters: {
        tiktooId: videoId
      } as any
    })) as any;
    return videoInfo;
  }
  async addAccount() {
    this.setState({ isBusyCreate: true })
    const email = this.state.tikEmail || uuidv4();
    const url = this.state.tikUrl || "";
    //'https://vm.tiktok.com/ZGJXHxWLr/'
    ///'https://www.tiktok.com/@t_bianca_13/video/7244457795909586203?_r=1&_t=8delJtplCaR'
    //'https://vm.tiktok.com/ZGJXHxWLr/'
    //if url contains vm.tiktok make a fetch request to get the video id
    let videoInput: any
    if (url) {
      let response = "";
      if (url.indexOf("vm.tiktok") !== -1) {
        response = await (await fetch(url)).text();
      }

      //extract the id 7244457795909586203 from a string like <meta data-rh="true" property="og:url" content="https://www.tiktok.com/@t_bianca_13/video/7244457795909586203?_t=8delJtplCaR&amp;_r=1" />
      const id = url.indexOf("vm.tiktok") !== -1 ? response.split('video/')[1].split('?')[0] : url.split('video/')[1].split('?')[0];
      let videoInfo = await this.getVideoInfo(id);
      //console.log("response", response)
      console.log("id", id)
      console.log("videoInfo", JSON.stringify(videoInfo))

      videoInput = {
        id: email,
        tiktokId: videoInfo.aweme_detail.aweme_id,
        avatarUrl: videoInfo.aweme_detail.author.avatar_medium?.url_list[0],
        videoUrl: videoInfo.aweme_detail.video.play_addr.url_list[0],
        previewUrl: videoInfo.aweme_detail.video.cover.url_list[0],
        tiktokProfile: videoInfo.aweme_detail.author.unique_id,
        type: "fast",
        addedTime: new Date().getTime()
      }
    } else {
      videoInput = {
        id: email,
        avatarUrl: "",
        videoUrl: "",
        previewUrl: "",
        tiktokProfile: "",
        type: "premium",
        addedTime: new Date().getTime()
      }
    }
    console.log("videoInput", videoInput)
    await API.graphql(graphqlOperation(createPromoters, { input: videoInput }));
    this.setState({ isBusyCreate: false })
  }
  openTikTokProfile(item: any) {
    Linking.openURL(`https://www.tiktok.com/@${item.tiktokProfile}`);
  }
  async deleteItem(item: any) {
    this.setState({ isBusy: true })
    await API.graphql(graphqlOperation(deletePromoters, { input: { id: item.id } }));
    //remove item from state
    const oldItems = this.state.items;
    const index = oldItems.indexOf(item);
    oldItems.splice(index, 1);
    this.setState({ items: oldItems, isBusy: false })

  }

  render() {
    const description = () => {
      return this.state.selectedVideo ? <View style={{
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        marginTop: 0,
        position: 'absolute',
        left: 0,
        bottom: 0,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',

      }}> <Text style={[{
        color: 'white',
        fontSize: 13,
        fontWeight: 'bold',
        width: '100%',
        padding: 0,
        marginTop: 0,
        textAlign: 'left'
      }]}>{this.state.selectedVideo.description}</Text>
      </View> : null
    }
    const busyIndicator = () => {
      if (this.state.isBusy) {
        return <ActivityIndicator size="large" style={style.busyIndicator} color={colors.busyIndicator} />
      }
    }
    return (
      <View style={style.page}>
        <View style={[style.container]}>
          {this.context.state.isAdmin ?
            <RadioGroup isRow={true} activeColor={colors.primaryColor} style={{
              marginTop: 10,
              marginBottom: 10
            }} textColor={colors.textColor} initial={Platform.OS === "web" && this.context.state.isAdmin ? 1 : 0} textStyle={{
              color: colors.textColor,
              fontSize: 18
            }}
              data={[
                {
                  label: 'View',
                  type: 'list'
                },
                {
                  label: 'Create',
                  type: 'create'
                }
              ]}
              selectedBtn={(e: any) => {
                console.log("e", e)
                this.setState({ viewType: e.type })
              }}
            /> : null}
          {this.state.viewType === 'list' && Platform.OS !== "web" ? <View onLayout={(event) => {
            if (!this.state.cheight || (this.cheight && this.cheight !== event.nativeEvent.layout.height)) {
              this.cheight = event.nativeEvent.layout.height;
              this.setState({ cheight: event.nativeEvent.layout.height })
            }
          }} style={[style.fullWidth, { flex: 1, padding: 0, paddingBottom: 0 }]}>
            <FlatList ref={ref => this.flatListRef = ref} onEndReached={() => {
              this.getListItems(false);
            }}
              data={this.state.items}
              horizontal={false}
              contentContainerStyle={{
                width: '100%',

                flexDirection: 'column'
              }} style={{
                flex: 1,
                maxWidth: 500,
                width: '100%'
              }}
              viewabilityConfig={{
                itemVisiblePercentThreshold: 50
              }}
              onViewableItemsChanged={this.onViewableItemsChanged}
              keyExtractor={(item) => item.previewUrl}
              renderItem={({ item, index }) => (
                <View style={[styles.imageContainer, {
                  height: this.state.cheight - (this.context.state.isAdmin ? 10 : 10),
                  borderRadius: colors.borderRadius,
                  overflow: 'hidden',
                  marginBottom: 10
                }]}>
                  <View style={[style.fullSize]}>
                    {this.state.selectedVideo?.id !== item.id && index !== this.state.currentIndex + 1 ?
                      <Image source={{ uri: item.previewUrl }} style={styles.image} /> :
                      <navigationService.props.VideoPlayer source={{ uri: item.videoUrl }}   // Can be a URL or a local file.
                        ref={(ref: any) => {

                        }}

                        paused={this.context.state.screen !== 'Promoters' || index === this.state.currentIndex + 1}
                        showOnStart={false}
                        onShowControls={() => {
                          this.setState({ showControls: true })
                        }}
                        onHideControls={() => {
                          this.setState({ showControls: false })
                        }}
                        onEnd={() => {
                          if (index < this.state.items.length - 1) this.flatListRef?.scrollToIndex({
                            animated: true,
                            index: index + 1
                          });
                        }}
                        disableBack={true}
                        constrols={false}
                        resizeMode="cover"
                        progressUpdateInterval={1000}
                        style={{
                          width: "100%",
                          flex: 1,
                          padding: 0
                        }} />}

                  </View>
                  {!this.state.showControls ? <TouchableOpacity style={{
                    position: 'absolute', left: 0,
                    width: '100%',
                    bottom: 0
                  }} onPress={this.openTikTokProfile.bind(this, item)}><View style={{
                    width: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    marginTop: 0,
                    height: 100,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',

                  }}>

                      <View style={[style.row, style.fullSize, style.vcenter, {}]}>

                        <Image style={{ resizeMode: 'cover', width: 60, height: 60, borderWidth: 5, borderColor: "#ffffff", borderRadius: 30 }} source={{
                          uri: item.avatarUrl
                        }}></Image>
                        <Text style={{ fontSize: 23, flex: 1, color: "#ffffff", marginLeft: 20 }}>{item.tiktokProfile}</Text>
                        {this.context.state.isAdmin ? <View style={[{}]}>
                          <Entypo.Button backgroundColor={'transparent'} underlayColor={'transparent'} onPress={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.deleteItem(item);
                          }} name="trash" size={24} color={"#ffffff"} />

                        </View> : null}
                      </View>


                    </View></TouchableOpacity> : null}
                </View>
              )}
              showsVerticalScrollIndicator={false}
              pagingEnabled={true}
            />


          </View> : <View style={[style.fullWidth, style.column, { flex: 1, padding: 10 }]}>
            <TextInput placeholder='Email' onChangeText={(text: string) => {
              this.setState({ tikEmail: text })
            }} style={[style.fullWidth, style.textInput]}></TextInput>
            <TextInput placeholder='Url' onChangeText={(text: string) => {
              this.setState({ tikUrl: text })
            }} style={[style.fullWidth, style.textInput, { marginTop: 10 }]}></TextInput>
            <View style={{ flex: 1 }}></View>
            <PrimaryButton isBusy={this.state.isBusyCreate} align={'left'} onPress={this.addAccount.bind(this)} label={'Add Account'} style={{
              width: '100%',
              marginTop: 10
            }} />
          </View>}
          {busyIndicator()}
        </View>
      </View>

    )
  };

}

const styles = StyleSheet.create({
  imageContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});
export default FastClipsScreen;
