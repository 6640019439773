import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image, ViewStyle, TextStyle } from 'react-native';
import colors from '../constants/colors';
import style from '../constants/style';
import { AppContext, IContext } from '../store/app-provider';
import { Feather } from '@expo/vector-icons';
interface State {
    initial?: number
}
interface Props {
    isRow?: boolean,
    activeColor?: string,
    textColor?: string,
    initial?: number,
    textStyle?: TextStyle,
    data?: { label: string, type: string }[],
    selectedBtn?: any,
    style?: ViewStyle
}
class RadioGroup extends React.Component<Props, State>  {
    static contextType = AppContext;
    declare context: IContext


    constructor(props: any) {
        super(props);
        this.state = { initial: this.props.initial }

    }

    componentDidMount() {

    }
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.initial !== this.props.initial) {
            this.setState({ initial: this.props.initial })
        }
    }


    render() {

        return (
            <View style={[!this.props.isRow ? style.column : style.row, this.props.style]}>
                {this.props.data?.map((item: any, index: number) => {
                    return (
                        <TouchableOpacity key={index + item.label} onPress={() => { this.props.selectedBtn(item); this.setState({ initial: index }) }} >
                            <View style={[style.row, { alignItems: 'center', justifyContent: 'flex-start', padding: 10, borderRadius: 25, backgroundColor: this.state.initial == index ? this.props.activeColor : '#ffffff' }]}>
                                <View style={[style.row, style.hcenter, style.vcenter, { width: 24, height: 24, borderRadius: 12, borderColor: this.props.activeColor, borderWidth: 1, backgroundColor: "#ffffff" }]}>
                                    {this.state.initial === index ? <Feather name="check" size={18} color={this.props.activeColor} /> : null}
                                </View>
                                <Text style={[this.props.textStyle, { marginLeft: 10, color: this.state.initial == index ? '#ffffff' : this.props.textColor }]}>{item.label}</Text>
                            </View>
                        </TouchableOpacity>
                    )

                })
                }
            </View>
        )
    };

}


const styles = StyleSheet.create({
    mainTitle: {

        color: colors.secondaryColor,
        fontSize: 35,
        fontWeight: 'bold'
    },
    pageTitle: {
        width: '100%',
        textAlign: 'center',
        color: colors.secondaryColor,
        fontSize: 15,
        fontWeight: 'bold'
    },
});



export default RadioGroup;
