/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://fvj5npvqczcydcasj5qiubrr5e.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fexihzo3cfc7dmbtzpigef4uja",
    "aws_cloud_logic_custom": [
        {
            "name": "lyritunesrest",
            "endpoint": "https://vvvdgjzn49.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:6c67def5-283b-4fcd-a3ce-c72ad7ef6e22",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_usOQCrwhJ",
    "aws_user_pools_web_client_id": "5h55pari083hpjog6vgs0mfdeb",
    "oauth": {
        "domain": "lyritunes-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,lyritunes://,https://www.lyritunes.com/",
        "redirectSignOut": "http://localhost:19006/,lyritunes://,https://www.lyritunes.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lyritunes101815-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "43340c0d7c6546fbbb0c376ba483028c",
                "region": "us-east-1"
            }
        }
    }
};


export default awsmobile;
